.shop_cards_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 30px;
}

.shop_pagination {
  cursor: pointer;
  max-width: 1200px;
  margin: 60px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  button {
    border: none;
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    padding: 19px 23px 18px 24px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
}

.shop_empty_list {
  min-height: 475px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #808080;
  }
}
