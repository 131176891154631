.sellpage_main {
  background: #f8f9fa;
  min-height: 80vh;
}

.title {
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
}
