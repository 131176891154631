.contact_main {
  min-height: 85vh;
  background-color: #f6f7f9;
}

.contact_container {
  max-width: 1200px;
  margin: 100px auto 200px;

  h1 {
    padding-bottom: 20px;
    margin-bottom: 60px;
    border-bottom: 1px solid #ededed;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.24;
    color: #333;
    text-transform: uppercase;
  }

  select::-webkit-scrollbar,
  textarea::-webkit-scrollbar {
    width: 6px;
    margin-block: 8px;
    writing-mode: horizontal-tb;
  }

  select::-webkit-scrollbar-track,
  select::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  select::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-track {
    margin-block: 8px;

    background-color: #ededed;
  }

  select::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
  }

  option {
    padding: 18px 30px 18px 20px;
    height: 54px;
    font-size: 12px;
    font-weight: 500;
    color: #222;
    border-bottom: 1px solid #ededed;
    text-transform: capitalize;
  }
}

.form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  row-gap: 30px;

  input:focus,
  select:focus,
  textarea:focus {
    outline: 1px solid #0046a6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'] {
    height: 54px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
  }

  input::placeholder,
  textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #bababa;
  }

  fieldset {
    border: none;
    padding: 0;
    margin-inline: 0;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
  }

  .label_title {
    margin-bottom: 10px;
  }

  .form_label {
    margin-bottom: 40px;
  }

  .form_box1,
  .form_box2 {
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .form_box1 {
    width: 646px;
    padding: 30px 40px 0;
  }

  .form_box2 {
    max-width: 524px;
    padding: 30px 0 40px;
  }
}

.statement_input,
.email_input,
.inquiry_title_input {
  width: 100%;
  padding: 18px 18px 18px 20px;
}

.mobile_fieldset {
  display: flex;
  flex-direction: column;

  .mobile_controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_select {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 27.74%;
  }

  .mobile_input {
    width: 68.7%;
    padding: 18px 20px 18px 20px;
  }
}

.custom_select {
  display: none;
  max-height: 171px;
  position: absolute;
  top: 54px;
  overflow-y: scroll;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom_select_btn {
  padding: 18px 20px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  span {
    text-transform: capitalize;
  }

  img {
    transition: all 0.2s ease-in-out;
  }
}

.inquiry_select {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  select {
    overflow: hidden;
    height: 164px;
  }

  option:last-child {
    border-bottom: 0;
  }
}

.detail {
  width: 100%;
  height: 145px;
  max-height: 145px;
  -ms-overflow-style: scrollbar;
  resize: none;
  padding: 20px;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
  line-height: 130%;
  letter-spacing: 1px;
}

.select_file_label {
  margin-bottom: 40px;
  padding-inline: 40px;
  padding-bottom: 82px;
  border-bottom: 1px solid #ededed;
}

.file_select {
  margin-bottom: 40px;
  height: 54px;
  display: flex;
  justify-content: space-between;

  .file_select_window {
    height: inherit;
    width: 73%;
    padding: 18px 20px 18px 20px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #bababa;
  }

  input[type='file'] {
    width: 97px;
    border: none;
    outline: none;
  }

  input[type='file']::-webkit-file-upload-button {
    visibility: hidden;
  }

  input[type='file']::before {
    cursor: pointer;
    width: 97px;
    height: 54px;
    content: 'Browse';
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    background: #0046a6;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
}

.files_container {
  display: flex;
  justify-content: space-between;

  .files_box {
    width: 73.65%;
    height: 240px;
    padding: 18px 20px 18px 20px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97px;
    height: 54px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #222;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #222;
  }
}

.user_agreement {
  padding-inline: 30px;
  padding-bottom: 40px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    color: #808080;
    margin-bottom: 30px;
  }

  label {
    display: flex;
    align-items: center;
    align-self: flex-end;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #222;
    }
  }
}

.form_control_btns {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 212px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 29px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .cancel_btn {
    border: solid 1px #222;
    background-color: #fff;
    color: #222;
    margin-right: 20px;
  }

  .submit_btn {
    border: none;
    border-radius: 29px;
    background-color: #0046a6;
    color: #fff;
    &:disabled {
      background-color: #cccccc;
      cursor: default;
    }
  }
}
