.pattern_hero {
  box-sizing: border-box;
  padding-top: 226px;
  height: 500px;
}

.title {
  font-size: 40px;
  font-weight: 800;
  line-height: 0.95;
  letter-spacing: 4px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 154px;
}
