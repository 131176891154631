.support_sidebar_containder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 260px;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  a,
  span {
    width: 100%;
    padding: 30px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    text-decoration: none;
    cursor: pointer;
  }

  a:not(:last-child),
  span:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
}
