.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal_container {
  margin: 15px;
  min-width: 450px;
  // min-height: 220px;

  padding: 20px;

  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  background-color: #fff;

  display: flex;
  flex-direction: column;

  hr {
    width: 100%;
    border: 0.5px solid #ededed;
    margin: 0 auto 20px;
    left: 0;
  }

  .modal_title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
  }

  .modal_text {
    font-size: 14px;
    font-weight: 600;
    color: #222;
    margin-bottom: 40px;
    text-transform: math-auto;
  }

  .modal_controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      width: 150px;
      height: 42px;
      border-radius: 21px;

      display: flex;
      align-items: center;
      justify-content: center;

      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    .modal_cancel {
      border: solid 1px #222;
      background: #fff;
      color: #222;
    }

    .modal_confirm {
      background: #0046a6;
      border: none;
      color: #fff;
    }
  }
}
