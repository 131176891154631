//variables
$desktopSelectWidth: 200px;
$desktopLabelWidth: 220px;

.hero {
  padding-top: 150px;
  padding-bottom: 353px;
  // background: url('../../img/hero_background.png') no-repeat;
  // background-size: cover !important;
  // background-position: center !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  margin-bottom: 50px;
  font-size: 44px;
  font-weight: 800;
  text-align: center;
  color: #222;
}
