.shop_products {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.shop_products_main {
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(auto, 300px) minmax(auto, 1200px) minmax(
      auto,
      300px
    );
  gap: 50px;
}
