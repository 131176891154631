.fieldset,
.label_input {
  position: relative;

  .validation_warn {
    margin-bottom: 0;
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    font-weight: 600;
    color: #ff4b4b;
  }

  select::-webkit-scrollbar,
  textarea::-webkit-scrollbar {
    width: 6px;
    margin-block: 8px;
    writing-mode: horizontal-tb;
  }

  select::-webkit-scrollbar-track,
  select::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  select::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-track {
    margin-block: 8px;

    background-color: #ededed;
  }

  select::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
  }

  option {
    padding: 18px 30px 18px 20px;
    height: 54px;
    font-size: 12px;
    font-weight: 500;
    color: #222;
    border-bottom: 1px solid #ededed;
    text-transform: capitalize;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}
.subtitle {
  margin-bottom: 60px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.profile_icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.data_title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #222;
}

.label_input,
.label_input_fieldset {
  display: flex;
  width: 100%;
}

.label_input {
  flex-direction: column;
  margin-bottom: 40px;

  span {
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }

  input {
    box-sizing: border-box;
    padding-left: 20px;
    height: 55px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #222;
  }

  input::-webkit-calendar-picker-indicator {
    opacity: 100;
    margin-right: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.label_input_fieldset {
  height: 55px;
  align-items: center;

  p {
    box-sizing: border-box;
    width: 147px;
    height: 15px;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #808080;
  }

  input {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    border: none;
    border-left: 1px solid #ededed;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  input:focus {
    outline: none;
    background: none;
  }
}

.fieldset_container {
  width: 100%;
  margin-bottom: 40px;
}

.fieldset {
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
}

.label_upload {
  padding-left: 20px;
  height: 110px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;

  .input_upload {
    position: relative;
    margin-bottom: 6px;
    width: 64px;
    height: 21px;
  }

  .input_upload::-webkit-file-upload-button {
    visibility: hidden;
  }

  .input_upload:focus {
    outline: none;
  }

  .input_upload::before {
    top: 0;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    content: 'Upload';
    display: inline-block;
    border-radius: 14px;
    background-color: #0046a6;
    padding: 5px 10px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
  }
}

.upload_container {
  margin-top: 34px;
  margin-bottom: 34px;
  margin-left: 10px;
}

.profile_desc {
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}

.profile_desc_info {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}

.pass_descr {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: #808080;
  margin-bottom: 10px;
}

.btn_container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  button,
  a {
    width: 220px;
    height: 58px;
    border-radius: 100px;
    box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .cancel_btn {
    display: flex;
    border: 1px solid #0046a6;
    background-color: transparent;
    color: #0046a6;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .register_btn {
    border: none;
    background-color: #0046a6;
    color: #fff;
    &:disabled {
      background-color: #cccccc;
      cursor: default;
    }
  }

  button:disabled {
    background-color: #cccccc !important;
    cursor: not-allowed;
  }
}

.country_fieldset {
  border: none;
  background: transparent;
  border-radius: none;
  box-shadow: none;
  margin-bottom: 40px;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }
  input {
    padding: 19px 20px;
  }

  input,
  select {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
  }

  input,
  option {
    cursor: pointer;
  }

  select {
    margin-top: 5px;
    position: absolute;
    z-index: 100;
    outline: 1px solid #0046a6;
    overflow-y: scroll;
    height: 250px;

    option:hover {
      color: #0046a6;
    }
  }
}

.dropdown {
  position: relative;
  input {
    transition: all 0.3s ease-in-out;

    background-position: 95%;
    background-repeat: no-repeat;
  }

  input:focus {
    background-position: 95%;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
    position: absolute;
    top: 19px;
    z-index: 0;
    left: 20px;
  }
}

.label_checkbox {
  margin-bottom: 20px;
}

.policy_service {
  display: flex;
  align-items: center;

  :first-child {
    margin-right: 6px;
  }

  :last-child {
    margin-left: auto;
  }

  input {
    padding-left: 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
  }

  button {
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 500;
    color: #808080;
    text-decoration: underline;
  }
}

.mobile_fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  background: transparent;
  border-radius: none;
  box-shadow: none;
  margin-bottom: 40px;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }

  .mobile_controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_select {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  .mobile_input {
    width: 60%;
    padding: 18px 20px 18px 20px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
  }
}

.custom_select {
  display: none;
  // width: 100%;
  max-height: 180px;
  position: absolute;
  top: 54px;
  overflow-y: scroll;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom_select_btn {
  padding: 18px 20px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  span {
    text-transform: capitalize;
  }

  img {
    transition: all 0.2s ease-in-out;
  }
}
