.approval_section {
  max-width: 1200px;
  margin: 60px auto 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

//tr grid 2.5fr 4fr 1fr 1fr 1fr

.approval_table {
  width: 100%;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  border-radius: 10px;
  margin-bottom: 30px;

  thead,
  tbody {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .td_credit {
      margin-inline: auto;
    }
  }

  thead {
    background-color: #f5f9ff;
    height: 75px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #ededed;

    .td_product {
      margin-left: 22%;
    }
  }

  tbody {
    background: #fff;

    tr {
      td {
        padding-block: 40px;
      }

      .td_date {
        margin-left: 7px;
      }

      .td_product {
        text-transform: uppercase;
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }
  }

  tr {
    padding-left: 56px;
    padding-right: 39px;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: #222;

    .td_pattern {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 160px;
        max-height: 82px;
      }
    }

    .td_product {
      max-width: 400px;

      h4,
      p {
        text-transform: uppercase;
      }

      h4 {
        color: #222;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.83;
        color: #808080;
      }
    }

    .td_date {
      justify-self: start;
    }

    .td_credit {
      justify-self: end;
    }

    .td_status,
    .td_status_rejected {
      margin-left: 20%;
      justify-self: center;
    }

    .td_status_rejected {
      button {
        padding: 0;
        border: none;
        background: transparent;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 600;
        color: #ff4b4b;
      }
    }
  }

  .tr_nodata {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .td_nodata {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #808080;
      }
    }
  }
}

.rejected_modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .modal_container {
    position: relative;
    width: 450px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    background-color: #fff;
    padding: 40px 77px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #222;
    }

    hr {
      margin: 22px 0 20px;
      position: relative;
      left: 0;
      border: 0.5px solid #ededed;
      width: calc(100% + 154px);
    }

    button {
      width: 150px;
      height: 42px;
      padding: 12px 40px;
      border-radius: 29px;
      border: none;
      background-color: #0046a6;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }
  }
}
