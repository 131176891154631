.item {
  width: 100%;
}

.list_item {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 30px;

  .item_date {
    margin-left: auto;
    font-size: 12px;
    font-weight: 500;
    color: #222;
  }
}

.item_sponsored {
  position: absolute;
  z-index: 1;
  width: 70px;
  height: 70px;

  img {
    width: 100%;
    height: 100%;
  }
}

.item_details {
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;

  .item_details_upper {
    display: flex;
    flex-direction: column;

    .item_details_date {
      font-size: 12px;
      font-weight: 500;
      color: #222;
    }

    .item_details_categories {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.57;
      color: #222;
    }

    .item_details_subcategories {
      font-size: 12px;
      font-weight: 600;
      color: #808080;
      text-transform: uppercase;
    }
  }

  .item_details_lower {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .item_lower_detail {
      display: flex;
      align-items: center;

      .item_detail_name {
        font-size: 12px;
        font-weight: 600;
        color: #222;
        padding-right: 10px;
        border-right: 1px solid #ededed;
        margin-right: 10px;
      }

      .item_detail_value {
        font-size: 12px;
        font-weight: 500;
        color: #222;
      }
    }
  }
}

.item_container {
  position: relative;
  width: 100%;
  height: 162px;
  padding-left: 30px;
  display: flex;
  align-items: center;

  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  input {
    margin-right: 10px;
    position: relative;
    z-index: 300;
  }

  .list_pattern_img {
    min-width: 159px;
    max-width: 159px;
    max-height: 82px;
    margin-right: 35px;
  }
}

.item_deleted {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgba(165, 189, 213, 0.11);
  background-color: rgba(0, 0, 0, 0.8);

  p {
    text-transform: uppercase !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #fc5252 !important;
  }
}

.item_uploader_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item_uploader {
  display: flex;
  align-items: center;

  img {
    width: 30px !important;
    height: 30px;
    margin-right: 4px;
    border-radius: 50%;
    object-fit: cover;
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #222;
  }
}
