.filter_label {
  margin-bottom: 20px;

  label,
  select,
  option,
  button {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  position: relative;
  width: 179px;
  height: 46px;
  padding: 14px 20px;
  border-radius: 8px;
  border: 1px solid #ededed;
  background-color: #fff;
  display: flex;
  align-items: center;
  align-self: flex-end;

  button {
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    transition: 0.3s ease-in-out;
  }

  select {
    z-index: 100;
    top: 56px;
    left: 0;
    position: absolute;
    width: 100%;
    appearance: none;
    outline: none;
    border-radius: 8px;
    border: 1px solid #ededed;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    gap: 20px;

    option {
      margin: 20px;
      cursor: pointer;
    }

    option:hover {
      color: #0046a6;
    }

    option:active {
      background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
    }
  }
}
