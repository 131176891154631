.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .modal_container {
    position: relative;
    width: 450px;
    border-radius: 4px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    background-color: #fff;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 18px;
      color: #222;
      margin-top: 40px;
    }
    .content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #222;
        white-space: pre-line;
        text-align: center;
      }
    }

    .group_btn {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #ededed;
      column-gap: 20px;
      button {
        height: 42px;
        padding: 12px;
        border-radius: 4px;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 500;
      }
      .btn_confirm {
        margin-left: 10px;
        background-color: #1a73e8;
        color: #fff;
      }

      .btn_cancel {
        color: #222;
        border: none;
      }
    }
  }
}
