.order_section {
  margin: 100px auto 0;
  max-width: 1200px;

  select::-webkit-scrollbar,
  textarea::-webkit-scrollbar {
    width: 6px;
    margin-block: 8px;
    writing-mode: horizontal-tb;
  }

  select::-webkit-scrollbar-track,
  select::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  select::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-track {
    margin-block: 8px;

    background-color: #ededed;
  }

  select::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
  }
}

.validation_warn {
  margin-bottom: 0;
  position: absolute;
  bottom: -19px;
  font-size: 12px;
  font-weight: 600;
  color: #ff4b4b;
}

.order_title {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
}

.order_content {
  display: grid;
  grid-template-columns: minmax(auto, 646px) minmax(auto, 524px);
  column-gap: 30px;
}

.details_container,
.order_form {
  padding-block: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
}

.content_title_container {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 40px;
}

.content_title {
  font-size: 16px;
  font-weight: bold;
  color: #222;
}

.order_form {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .form_content {
    padding-inline: 40px;
    margin-bottom: 60px;
    button:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }

  .same_info_label {
    display: flex;
    align-items: center;

    p {
      margin-left: 6px;
      font-size: 14px;
      font-weight: 500;
      color: #222;
    }
  }

  .form_label {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;

    p:last-child {
      margin-top: 4px;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 600;
      color: #ff4b4b;
    }

    p {
      font-size: 14px;
      font-weight: bold;
      color: #222;
      margin-bottom: 10px;
    }

    input {
      padding: 18px 20px;
      width: 100%;
      height: 54px;
      font-size: 14px;
      font-weight: 500;
      color: #222;
      border-radius: 10px;
      box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
      border: solid 1px #ededed;
      background-color: #fff;
    }

    // select {
    //   padding: 18px 16px;
    //   width: 100%;
    //   height: 54px;
    //   font-size: 14px;
    //   font-weight: 500;
    //   color: #222;
    //   border-radius: 10px;
    //   box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    //   border: solid 1px #ededed;
    //   background-color: #fff;
    // }
  }

  .form_label_checkbox {
    display: flex;
  }

  .form_label_select {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 40px;

    select,
    button {
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
      border: solid 1px #ededed;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      color: #222;
    }

    button {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #222;
      }
    }

    select::-webkit-scrollbar {
      display: none;
    }

    select {
      // display: none;
      max-height: 200px;
      width: 100%;
      position: absolute;
      top: 85px;
      z-index: 100;
      margin-top: 5px;
      appearance: none;
      outline: none;
      overflow-y: scroll;

      option {
        cursor: pointer;
      }

      option:hover {
        color: #0046a6;
      }

      option:active {
        background-color: #fff;
      }

      option:not(:first-child) {
        margin-top: 20px;
      }
      option:checked {
        background-color: #fff;
      }
    }
  }

  .form_coupon_label {
    display: grid;
    justify-content: space-between;
    grid-template-columns: minmax(auto, 458px) minmax(auto, 88px);

    p {
      grid-column: 1/3;
    }

    .form_coupon_apply {
      padding: 18px 20px;
      border-radius: 10px;
      box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
      background-color: #0046a6;
      border: none;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .form_currency {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 300px) minmax(auto, 71px);
    column-gap: 20px;

    .currency_label {
      display: flex;
      flex-direction: column;
      grid-column: 1/1;
      grid-row: 1/3;

      p {
        font-size: 14px;
        font-weight: bold;
        color: #222;
        margin-bottom: 10px;

        span {
          font-size: 12px;
          font-weight: 500;
          color: #808080;

          span {
            color: #0046a6;
          }
        }
      }

      input {
        padding: 18px 20px;
        width: 100%;
        height: 54px;
        font-size: 14px;
        font-weight: 500;
        color: #222;
        border-radius: 10px;
        box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
        border: solid 1px #ededed;
        background-color: #fff;
      }
    }

    .currency_all_label {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-column: 2/2;
      grid-row: 2/2;
      margin-bottom: 18px;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #222;
      }
    }
  }
}

.details_container {
  max-height: 1020px;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.details_content {
  padding-inline: 40px;
}

.details_content:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 40px;
}

.details_order {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 382px;
  overflow-y: scroll;

  .details_product {
    display: flex;
    height: 114px;
    align-items: center;

    img {
      max-width: 128px;
      height: 100%;
      margin-right: 10px;
      border-radius: 10px;
      object-fit: cover;
    }

    .details_product_info {
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #222;
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      .product_category {
        font-size: 14px;
        font-weight: 500;
        color: #808080;
        margin-bottom: 6px;
      }

      .product_quater {
        font-size: 12px;
        font-weight: 500;
        color: #222;
        margin-bottom: 14px;
      }

      .product_price {
        font-size: 12px;
        font-weight: bold;
        color: #222;
      }
    }
  }
}

.details_order::-webkit-scrollbar {
  width: 4px;
  writing-mode: horizontal-tb;
}

.details_order::-webkit-scrollbar-track,
.details_order::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

.details_order::-webkit-scrollbar-track {
  background-color: #ededed;
}

.details_order::-webkit-scrollbar-thumb {
  background-color: #222;
}

.details_pricing {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4,
  p {
    font-size: 14px;
    font-weight: 600;
  }

  h4 {
    color: #222;
  }

  p {
    color: #0046a6;
  }
}

.details_pricing:not(:last-child) {
  margin-bottom: 20px;
}

.details_total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h2,
  p {
    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    color: #222;
  }

  p {
    color: #0046a6;
  }
}

.details_warning {
  font-size: 12px;
  font-weight: 500;
  color: #808080;
  margin-bottom: 40px;
}

.details_pay_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 207px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.details_pay_controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    width: 217px;
    height: 58px;
    border-radius: 29px;
    font-size: 14px;
    font-weight: bold;

    box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
    border: none;
  }

  .details_paypal_pay_btn {
    color: #fff;
    background-color: #0046a6;
  }

  .details_card_pay_btn {
    border: solid 1px #222;
    background-color: #fff;
    color: #222;
  }
}

.country_fieldset {
  border: none;
  background: transparent;
  border-radius: none;
  box-shadow: none;
  margin-bottom: 40px;
  position: relative;
  padding: 0;

  .validation_warn {
    margin-bottom: 0;
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    font-weight: 600;
    color: #ff4b4b;
  }

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }
  input {
    width: 100%;
    padding: 19px 20px;
  }

  input,
  select {
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
  }

  input,
  option {
    cursor: pointer;
  }

  select {
    width: 100%;
    position: absolute;
    z-index: 100;
    outline: 1px solid #0046a6;
    overflow-y: scroll;
    height: 250px;

    option {
      padding: 18px 30px 18px 20px;
      height: 54px;
      font-size: 12px;
      font-weight: 500;
      color: #222;
      border-bottom: 1px solid #ededed;
      text-transform: capitalize;
    }

    option:hover {
      color: #0046a6;
    }
  }
}

.dropdown {
  position: relative;
  input {
    transition: all 0.3s ease-in-out;

    background-position: 95%;
    background-repeat: no-repeat;
  }

  input:focus {
    background-position: 95%;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
    position: absolute;
    top: 19px;
    z-index: 0;
    left: 20px;
  }
}

.mobile_fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  background: transparent;
  border-radius: none;
  box-shadow: none;
  margin-bottom: 40px;
  padding: 0;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }

  .mobile_controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_select {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  .mobile_input {
    width: 60%;
    padding: 18px 20px 18px 20px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
  }
}

.custom_select {
  display: none;
  // width: 100%;
  max-height: 180px;
  position: absolute;
  top: 54px;
  overflow-y: scroll;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  option {
    padding: 18px 30px 18px 20px;
    height: 54px;
    font-size: 12px;
    font-weight: 500;
    color: #222;
    border-bottom: 1px solid #ededed;
    text-transform: capitalize;
  }

  option:hover {
    color: #0046a6;
  }
}

.custom_select_btn {
  padding: 18px 20px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  span {
    text-transform: capitalize;
  }

  img {
    transition: all 0.2s ease-in-out;
  }
}

.buttons {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
  margin-right: 24px;
  border-right: 1px solid #ededed;

  button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .edit_btn {
    background-color: #0046a6;
    color: #fff;
  }

  .sell_btn {
    border: solid 1px #0046a6;
    background-color: #fff;
    color: #0046a6;
  }
}