.topic_container {
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  button {
    padding: 30px;
    height: 78px;
    background: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .details_content {
    padding: 30px 40px;

    h3 {
      font-size: 14px;
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 16px;
    }
    ul {
      &::marker {
        font-size: 14px;
      }
      display: list-item;
      list-style-type: decimal;
      margin-left: 16px;
      li {
        font-size: 14px;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  .dropdown_icon {
    transition: all ease-in-out 0.3s;
  }
}
