.mypattern_container {
  margin-bottom: 140px;
}

.profile {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 60px auto 65px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  hr {
    border: 0.5px solid #ededed;
    margin: 20px 0;
  }

  .profile_intro {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #222;
  }

  .profile_intro_textarea {
    margin-top: 20px;
    padding: 20px;
    width: 100%;
    height: 100px;
    border: 1px solid #ededed;
    border-radius: 10px;
  }
}

.profile_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  .profile_img {
    width: 70px;
    height: 70px;
    position: relative;
    margin-right: 10px;

    .profile_pic {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .profile_rank {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 24px;
      height: 24px;
    }
  }

  .profile_name {
    font-size: 30px;
    font-weight: bold;
    color: #222;
    flex-grow: 1;
  }

  .profile_introduction {
    display: flex;
    flex-direction: column;
    button {
      text-transform: uppercase;
      padding: 10px 15px;
      color: #222;
      border: 1px solid #222;
      border-radius: 25px;
      outline: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      img {
        margin-right: 5px;
      }
    }
  }
}

.profile_info {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  .info_item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;

    .item_icon {
      margin-bottom: 10px;
      width: 20px;
      height: 20px;
    }

    .item_upper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 65px;
      margin-bottom: 10px;

      img {
        margin: 0;
      }

      button {
        border: none;
        background: transparent;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        color: #808080;
        display: flex;
        align-items: center;

        img {
          margin-left: 6px;
          width: 5px;
          height: 10px;
        }
      }
    }

    p {
      margin-bottom: 10px;
    }

    p,
    span {
      font-size: 14px;
      font-weight: bold;
      color: #222;
    }
  }

  .info_item:not(:first-child) {
    padding-left: 30px;
  }

  .info_item:not(:last-child) {
    border-right: 1px solid #ededed;
  }
}

.title {
  max-width: 1200px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;

  .pattern_dd_filter {
    margin-left: 40px;
    box-sizing: border-box;
    position: relative;
    width: 200px;
    height: 46px;
    padding: 14px 20px;
    border-radius: 8px;
    border: 1px solid #ededed;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100%;
      background: transparent;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      transition: 0.3s ease-in-out;
    }

    select {
      z-index: 1000;
      top: 56px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 134px;
      appearance: none;
      outline: none;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #ededed;
      background-color: #fff;
      overflow: hidden;

      option {
        cursor: pointer;
      }

      option:hover {
        color: #0046a6;
      }

      option:active {
        background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
      }

      option:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    color: #222;
  }

  a {
    margin-left: 40px;
    box-sizing: border-box;
    padding: 10px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    display: flex;
    align-items: center;
    height: inherit;
    border: none;
    border-radius: 8px;
    background-color: #ff6900;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }
}

.pattern_categories {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-left: auto;

  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #222;

    span {
      text-transform: capitalize;
    }
  }
}

.selector_container {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.mypatterns_container {
  margin-bottom: 30px;
}
