.popup_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.popup_content {
  padding: 40px 30px 20px;
  width: 450px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .popup_title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
    text-align: center;
  }

  .popup_descr {
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #222;
  }

  hr {
    margin: 0;
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    border: 0.5px solid #ededed;
  }

  .controls {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 150px;
      height: 42px;
      padding: 12px 32px;
      border-radius: 29px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .controls_cancel {
      margin-right: 10px;
      border: solid 1px #222;
      background-color: transparent;
      color: #222;
    }

    .controls_rate {
      border: none;
      background-color: #0046a6;
      color: #fff;
    }
  }
}
