.pattern_container {
  max-width: 1820px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(auto, 260px) minmax(auto, 1200px) minmax(
      auto,
      260px
    );
  gap: 50px;
}

.cards_container {
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
}

.pattern_pagination {
  cursor: pointer;
  max-width: 1200px;
  margin: 60px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  button {
    border: none;
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    padding: 19px 23px 18px 24px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #222;
  }
}

.empty_list {
  min-height: 475px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #808080;
  }
}

@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
