.pagination_container {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  list-style: none;

  .pagination_item {
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    background-color: #fff;
    color: #222;
    cursor: pointer;
    width: 56px;
    height: 56px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }

  .item_selected {
    background-color: #0046a6;
    color: #fff;
  }

  .item_disabled {
    background: rgb(211, 211, 211, 0.6);
  }

  .arrow_left {
    transform: rotate(-90deg);
  }

  .arrow_right {
    transform: rotate(90deg);
  }
}
