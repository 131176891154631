.tags {
  margin-top: 40px;
  margin-bottom: 60px;
  padding-inline: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    margin-bottom: 4px;
  }

  span {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #808080;
  }

  .tag_label {
    position: relative;
    width: 432px;
    height: 55px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;

    input {
      border: none;
      outline: none;
      box-sizing: border-box;
      margin-left: 20px;
      height: 100%;
      width: 70%;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #222;
    }

    button {
      position: absolute;
      bottom: 17px;
      right: 20px;
      border-radius: 14px;
      border: none;
      background-color: #0046a6;
      height: 21px;
      width: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;
      line-height: 1.3;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .tags_container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 10px;

    .tag {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 34px;
      padding: 8px 10px;
      border-radius: 4px;
      border: solid 1px #ededed;

      .tagname {
        font-size: 12px;
        font-weight: 600;
        color: #222;
        margin-right: 10px;
        margin-bottom: 0;
      }

      button {
        border: none;
        background: transparent;
        width: 18px;
        height: 18px;
        padding: 0;

        img {
          width: 100%;
        }
      }
    }
  }
}
