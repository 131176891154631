$desktopSelectWidth: 200px;
$desktopLabelWidth: 220px;

.form {
  position: relative;
  max-width: 1200px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.2);
  background: #fff;
  z-index: 500;
}

.selectors {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  label {
    width: $desktopLabelWidth;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;

    button {
      background: transparent;
      border: none;
      outline: none;
    }
  }

  label:first-child {
    padding-left: 20px;
  }

  label:not(:first-child) {
    padding-left: 20px;
    border-left: 1px solid #ededed;
  }

  h4 {
    text-transform: capitalize;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    text-align: left;
  }

  .sublabel,
  .sublabel_selected {
    text-transform: capitalize;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
  }

  .sublabel {
    color: #bababa;
  }

  .sublabel_selected {
    color: #222;
    text-transform: uppercase;
  }

  select,
  option {
    font-size: 14px;
    font-weight: 500;
    color: #222;
    text-transform: uppercase;
    cursor: pointer;
  }

  select {
    display: flex;
    position: absolute;
    left: 0;
    margin-top: 31px;
    padding-top: 20px;
    padding-left: 20px;
    width: $desktopSelectWidth;
    max-height: 172px;
    appearance: none;
    background-color: #fff;
    outline: none;
    border: 1px solid #ededed;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.22);
    border-radius: 10px;
  }

  select::-webkit-scrollbar {
    width: 4px;
    margin-block: 10px;
    writing-mode: horizontal-tb;
  }

  select::-webkit-scrollbar-track,
  select::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  select::-webkit-scrollbar-track {
    margin-block: 20px;

    background-color: #ededed;
  }

  select::-webkit-scrollbar-thumb {
    background-color: #222;
  }

  option {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  option:hover {
    color: #0046a6;
    background-color: #fff;
  }

  option:active {
    background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
  }
}

.form_hr {
  margin-top: 0;
  margin-bottom: 10px;
  width: calc(100% - 2px);
  border: 1px solid #ededed;
  background: #ededed;
}

.btn_container {
  margin-right: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
  }
}

.reset_btn {
  border: none;
  background-color: transparent;
  font-size: 10px;
  font-weight: bold;
  color: #222;
  margin-right: 20px;

  img {
    width: 14px;
    height: 15px;
    margin-right: 4px;
  }
}

.search_btn {
  border: none;
  padding: 10px 20px;
  border-radius: 18px;
  background-color: #0046a6;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
