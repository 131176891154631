.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal_content {
  width: 580px;
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
}

.modal_head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  background: #222;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

.modal_body {
  background: #f6f7f9;
  scrollbar-width: thin;
}

.modal_rating {
  margin-top: 40px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding-inline: 70px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ededed;

  .rating_stars,
  .rating_chart {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.71;
      color: #222;
    }
  }

  .rating_stars {
    padding-right: 80px;
    border-right: 1px solid #ededed;

    .rating_value {
      margin-bottom: 8px;
      font-size: 30px;
      font-weight: 800;
      line-height: 0.6;
      color: #333;
    }

    .rating_count {
      margin-top: 30px;
      font-size: 12px;
      font-weight: 600;
      color: #222;
    }
  }

  .rating_chart {
    .chart_container {
      display: flex;
      column-gap: 20px;
    }

    .chart_item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item_line {
        position: relative;
        height: 93px;
        width: 10px;
        background: #ededed;
        border-radius: 500px;
        margin-bottom: 6px;

        span {
          position: absolute;
          bottom: 0;
          z-index: 100;
          display: block;
          width: 100%;
          background: #ffd500;
          // height: 75%;
          border-radius: 500px;
        }
      }

      .item_rating_value {
        font-size: 12px;
        font-weight: 600;
        color: #222;
      }
    }
  }
}

.customer_rating {
  padding: 35px 40px;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  p {
    margin-right: 30px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    color: #333;
  }
}

.modal_comment {
  padding: 30px 40px 40px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ededed;

  p {
    font-size: 12px;
    font-weight: 600;
    color: #222;
    margin-bottom: 10px;
  }

  textarea {
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.2);
    border: solid 1px #ededed;
    padding: 20px;
    max-height: 148px;
    background: transparent;
  }
}

.modal_btns {
  padding-block: 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  button {
    width: 150px;
    height: 42px;
    border-radius: 29px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .btn_cancel {
    background: transparent;
    border: solid 1px #222;
    color: #222;
  }

  .btn_register {
    background: #0046a6;
    border: none;
    color: #fff;
  }
}
