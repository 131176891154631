.modal {
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal_content {
  width: 480px;
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  // border-top-left-radius: 12px;
  // border-top-right-radius: 12px;
}

.modal_head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  background: #222;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
}

.modal_body {
  background: #fff;
  max-height: 470px;
  overflow-y: auto;
  // padding: 0 40px 0 40px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  p {
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }
  textarea {
    border: 1px solid #ededed;
    border-radius: 10px;
    height: 140px;
    margin-top: 10px;
    padding: 20px;
    width: 100%;
  }
  .title {
    height: 18px;
    margin: 40px 63px 10px 0px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    color: #222;
  }
  .info {
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
  }
  .info_row {
    display: flex;
    align-items: center;
    height: 18px;
    padding: 21px 0;
    &:not(:first-child) {
      border-top: 1px solid #ededed;
    }
    p:first-child {
      width: 110px;
      margin: 0 21px;
      font-size: 12px;
      font-weight: 600;
      color: #808080;
    }
    p:not(:first-child) {
      height: 18px;
      margin-left: 21px;
      font-size: 14px;
      font-weight: 500;
      color: #222;
    }
  }
  .grand_total {
    display: flex;
    justify-content: space-between;
    margin: 30px 0px 0px 0px;
    h2 {
      height: 22px;
      font-size: 18px;
      font-weight: bold;
      color: #222;
    }
    p {
      height: 22px;
      font-size: 18px;
      font-weight: bold;
      text-align: right;
      color: #0046a6;
    }
  }
  .product_row {
    display: flex;
    align-items: center;
    padding: 21px 20px;
    &:not(:first-child) {
      padding-top: 0;
    }
    .product_row_img {
      width: 76px;
      height: 68px;
      object-fit: contain;
      border-radius: 10px;
    }
    .product_row_info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;
      justify-content: center;
      span:first-child {
        height: 18px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #222;
      }
      span:not(:first-child) {
        height: 15px;
        font-size: 12px;
        font-weight: 500;
        color: #222;
      }
    }
    .product_row_price {
      height: 15px;
      font-size: 12px;
      font-weight: 600;
      text-align: right;
      color: #222;
      flex-grow: 1;
    }
  }
}

.modal_footer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-top: 1px solid #ededed;
}

.modal_btns {
  padding-block: 20px;

  button {
    width: 150px;
    height: 42px;
    border-radius: 29px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .btn_close {
    text-transform: uppercase;
    background: transparent;
    border: solid 1px #222;
    color: #222;
  }

  .btn_register {
    text-transform: uppercase;
    background: rgb(0, 70, 166);
    border: none;
    color: #fff;
  }
}
