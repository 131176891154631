.price_per_area {
  padding-inline: 40px;
  margin-top: 40px;

  p {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 40px;
    height: 58px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;

    li {
      cursor: pointer;
      height: inherit;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      color: #222;
      text-transform: capitalize;

      button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: 600;
        color: #222;
        text-transform: capitalize;
        border-radius: inherit;
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:not(:last-child) {
        border-right: 1px solid #ededed;
      }
    }
  }
}
