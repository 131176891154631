.react-calendar {
  position: absolute;
  // left: -1px;
  // bottom: -350px;
  z-index: 1500;
  width: 340px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #e6eaea;
  background-color: #fff;
  padding: 25px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 18px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  background: none;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__prev2-button
  .react-calendar__navigation
  button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation__arrow:enabled:hover,
.react-calendar__navigation__arrow:enabled:focus {
  border-radius: 100%;
  background-color: #e6e6e6;
}

// .react-calendar__navigation button:enabled:hover,
// .react-calendar__navigation button:enabled:focus {
//   background-color: #e6e6e6;
// }

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-calendar__month-view__weekdays__weekday {
  height: 15px;
  flex: none !important;
  width: 26px !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

// .react-calendar__month-view__days__day--weekend {
//   color: #d10000;
// }

.react-calendar__month-view__days {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  button {
    box-sizing: border-box;
    height: 26px;
    width: 26px !important;
    flex: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    abbr {
      height: 15px;
    }
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;

  background: none;
}

.react-calendar__tile:disabled {
  border-radius: 100%;
  background-color: #f0f0f0;

}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 100%;
}

.react-calendar__tile--now {
  border-radius: 100%;
  background: #76baff;
}

.react-calendar__tile--now abbr {
  border-radius: 100%;
  color: #fff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 100%;
  background: #0046a6;
  color: #fff;
}

.react-calendar__tile--hasActive {
  border-radius: 100%;
  background: #0046a6;
  color: #fff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  border-radius: 100%;
  background: #0046a6;
  color: #fff;
}

.react-calendar__tile--active {
  border-radius: 100%;
  background: #0046a6;
  color: #fff;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
