.sponsor_modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal_container {
  margin: 15px;
  width: 580px;
  max-height: 486px;
  background: #fff;

  hr {
    border: 0.5px solid #ededed;
    margin: 0 auto 20px;
    left: 0;
  }
}

.modal_header {
  position: relative;
  padding-block: 18px;
  max-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222222;
  padding: 17px;

  h2 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }

  button {
    padding: 0;
    position: absolute;
    border: none;
    background: transparent;
    right: 17px;
  }
}

.modal_content {
  padding-inline: 40px;

  .sponsor_balance {
    font-size: 20px;
    font-weight: bold;
    color: #222;
    margin-bottom: 40px;
  }

  .modal_subtitle {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }

  .modal_remaining {
    font-size: 16px;
    font-weight: bold;
    color: #0046a6;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    color: #222;
    margin-bottom: 40px;
  }

  .modal_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;

    input {
      padding: 20px;
      width: 100%;
      border-radius: 10px;
      border: solid 1px #ededed;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      color: #222;
      margin-right: 10px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      color: #222;
    }
  }
}

.modal_table {
  margin-bottom: 40px;
  border-collapse: collapse;
  border-top: 2px solid #0046a6;
  width: 100%;

  thead {
    padding: 0;
    margin: 0;
    background: #e5efff;
  }

  tbody {
    tr {
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
    }

    input[type='checkbox'] {
      cursor: pointer;
      appearance: none;
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid #dbdbdb;
      background: #fff;
    }

    input[type='checkbox']:checked {
      background: url('../../../../../img/check.svg') no-repeat;
      background-position: center;
    }
  }

  tr {
    height: 55px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    justify-items: center;

    .modal_head_name_td {
      padding-left: 50px;
    }

    .modal_name_td {
      text-transform: uppercase;
    }

    .modal_head_name_td,
    .modal_name_td {
      justify-self: start;
    }
  }

  td {
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }
}

.modal_btns {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 29px;
    width: 150px;
    height: 42px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .btn_cancel {
    border: solid 1px #222;
    color: #222;
    background: transparent;
  }

  .btn_profit,
  .btn_paypal {
    border: none;
    color: #fff;
    background: #0046a6;
    &:disabled {
      background-color: #cccccc;
      cursor: default;
    }
  }
}
