.title {
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.order_table {
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;

  thead,
  tbody {
    display: flex;
    flex-direction: column;
    justify-content: center;

    td:not(:first-child) {
      justify-self: center;
    }
  }

  thead {
    background-color: #f5f9ff;
    height: 75px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  tbody {
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .tr_date {
      padding-top: 30px;
      border-top: 1px solid #ededed;
    }

    .td_date {
      grid-column: 1/6;
      display: flex;
      align-items: center;
      // justify-content: space-between;

      p {
        font-size: 16px;
        font-weight: 600;
        color: #222;
      }

      button {
        border: none;
        background: transparent;
        text-transform: uppercase;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 600;
        color: #ff6900;
      }
    }

    .tr_product {
      padding-block: 20px;
    }

    .td_product {
      display: flex;
      align-items: center;

      img {
        width: 128px;
        height: 114px;
        border-radius: 10px;
        object-fit: cover;
        margin-right: 10px;
      }

      h2 {
        font-size: 14px;
        font-weight: bold;
        color: #222;
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: #808080;
      }
    }

    .more_action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button + button {
        margin-top: 5px;
      }
    }

    .td_shipping {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        text-transform: capitalize;
      }

      a {
        margin-top: 10px;
        color: #0081aa;
        text-decoration: underline;
      }

      button {
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: 600;
        color: #0046a6;
        text-decoration: underline;
      }
    }
  }

  .item_review_btn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #222;
    img {
      margin-right: 6px;
    }
  }

  .way_number {
    display: flex;
    align-items: center;
    margin-top: 10px;
    img {
      width: 21px;
      margin-left: 5px;
    }
  }

  .td_checkbox {
    display: flex;
    align-items: center;
  }

  td {
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }

  tr {
    display: grid;
    grid-template-columns: 0.2fr 2fr 0.9fr 0.9fr 0.9fr 0.8fr;
    align-items: center;
    padding-inline: 40px;
    min-height: 82px;
  }
}

.order_cancel_btn {
  margin-top: 20px;
  background: transparent;
  padding: 14px 20px;
  border-radius: 8px;
  border: solid 1px #222;
  font-size: 12px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
}

.shop_empty_list {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  img {
    margin-bottom: 10px;
    width: 30px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #808080;
  }
}
