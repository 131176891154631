.newpattern_subcats {
  margin-top: 40px;
  padding-inline: 40px;
}

.subcats_container {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #ededed;
}

.subcats_names {
  height: 58px;
  display: grid;
  // grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;

  li {
    cursor: pointer;
    height: inherit;
    width: 100%;

    button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      font-size: 14px;
      font-weight: 600;
      color: #222;
      text-transform: capitalize;
      border-radius: inherit;
    }

    &:first-child {
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }

    &:not(:last-child) {
      border-right: 1px solid #ededed;
    }
  }
}

.subcats_options {
  padding: 30px 40px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  row-gap: 20px;
  column-gap: 60px;
}

.subcat_option {
  label {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }
}
