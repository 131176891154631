//fonts
/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 10:05 PM */

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('./fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Light.woff') format('woff'),
    url('./fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Thin.eot');
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('./fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Thin.woff') format('woff'),
    url('./fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('./fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-SemiBold.woff') format('woff'),
    url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ExtraLight.eot');
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('./fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-ExtraLight.woff') format('woff'),
    url('./fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('./fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Bold.woff') format('woff'),
    url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('./fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Regular.woff') format('woff'),
    url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('./fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Medium.woff') format('woff'),
    url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ExtraBold.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('./fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-ExtraBold.woff') format('woff'),
    url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('./fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat-Black.woff') format('woff'),
    url('./fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

$font-family: Montserrat, sans-serif;

*,
*::after,
*::before {
  box-sizing: border-box;
}

html * {
  font-family: $font-family !important;
}

// presets
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

ul {
  list-style: none;
}
