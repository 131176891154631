.descr {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.bg_holder,
.text_container,
.text_container_bg {
  width: 50%;
  max-width: 960px;
  max-height: 600px;
}

.bg_holder {
  img {
    width: 100%;
  }
}

.text_container,
.text_container_bg {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text_container {
  padding-left: 360px;
  padding-right: 100px;
}

.text_container_bg {
  padding-left: 150px;
  position: relative;

  .text_bg_img {
    position: absolute;
    width: 100%;
    max-height: 600px;
    object-fit: contain;
    left: 0;
    z-index: 1;

    :nth-child(2) {
      transform: rotateX(180deg);
    }
  }

  .text_icon,
  .subtitle,
  .par {
    z-index: 2;
  }
}

.text_icon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #e5efff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.subtitle {
  max-width: 500px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: #222;
}

.par {
  max-width: 465px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #808080;
}
