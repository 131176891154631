.support_table {
  margin-top: 100px;
  border-radius: 10px;
  border: 1px solid #ededed;

  .table_head {
    height: 75px;
    background-color: #f5f9ff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .table_row {
      height: 100%;

      :nth-child(2) {
        margin-left: 383px;
      }
    }
  }

  .table_body {
    background-color: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .table_row {
    padding-block: 30px;
    min-height: 75px;
    display: grid;
    grid-template-columns: 1fr 7fr;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ededed;
  }

  .table_cell {
    margin-left: 70px;
    font-size: 14px;
    font-weight: 600;

    button {
      padding: 0;
      height: 18px;
      font-size: 14px;
      font-weight: 600;
      color: #222;
      border: none;
      background: transparent;
    }

    p {
      margin-bottom: 10px;
      max-width: 70%;
      font-size: 14px;
      font-weight: 500;
      color: #222;
    }
  }
}
