.mypage_title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }

  .iquiry_date_form {
    position: relative;
    height: 43px;
    display: flex;
    align-items: center;

    label {
      font-size: 12px;
      font-weight: 600;
      color: #222;

      span {
        margin-inline: 10px;
      }

      input {
        padding: 14px 20px;
        height: 100%;
        width: 179px;
        border: 1px solid #ededed;
        border-radius: 8px;
        background: #fff;
      }

      input[type='date']::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url('../../../img/icon_calendar.svg') no-repeat;
        width: 20px;
        height: 20px;
        border-width: thin;
      }
    }

    .inquiry_button {
      margin-left: 20px;
      padding: 14px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 8px;
      background-color: #0046a6;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }

    .reset_button {
      margin-left: 20px;
      padding: 14px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 8px;
      background-color: #ededed;
      font-size: 12px;
      font-weight: bold;
      border: 1px solid #222;
      color: #222;
      text-transform: uppercase;
    }
  }
}
