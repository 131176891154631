.packs_container {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 60px;
  justify-content: space-between;
}

.descr {
  max-width: 600px;
  margin: 0 auto 40px auto;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #222;
}

.total {
  margin: 0 auto 20px auto;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #0046a6;
}

.payment_controls {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  .payment_credit {
    max-width: 217px;
    height: 55px;
    padding-inline: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 29px;
    border: solid 1px #222;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }
}
