.subtitle {
  margin-bottom: 35px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
  color: #808080;
}

.pass_input {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #222;
  position: relative;

  p {
    margin-bottom: 10px;
  }

  input {
    padding-left: 20px;
    padding-right: 45px;
    height: 55px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #222;
  }

  input:focus {
    outline: 1px solid #0046a6;
  }

  input[type='password'] {
    font-family: Verdana;
    letter-spacing: 4px;
  }

  input[type='password']::-ms-reveal {
    display: none;
  }

  input[type='password']::-ms-clear {
    display: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  img {
    opacity: 0.7;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    right: 20px;
    top: 45px;
  }
}
