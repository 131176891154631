.container {
  height: 76px;
  max-width: 100%;
  margin: 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 123px;
  height: 40px;
  margin-right: max(calc((100vw - 1200px) / 2 - 147px), 11.1%);
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.nav {
  max-width: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;

  a {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
    text-decoration: none;
  }

  a:not(:last-child) {
    margin-right: 60px;
  }
}

.credit_holder {
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.25;
    color: #222;

    span {
      color: #ff6900;
    }
  }

  .credit_charge {
    width: 65px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 14px;
    background-color: #ff6900;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;
  }
}

.buttons {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
  margin-right: 24px;
  border-right: 1px solid #ededed;

  button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .edit_btn {
    background-color: #0046a6;
    color: #fff;
  }

  .sell_btn {
    border: solid 1px #0046a6;
    background-color: #fff;
    color: #0046a6;
  }
}

.header_login {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.header_cart {
  position: relative;
  padding: 0 25px 0 0;
  height: 100%;
  background: transparent;
  border: none;
  border-right: 1px solid #ededed;
  margin-right: 25px;

  .cart_notify {
    display: flex;
    align-items: center;
    width: 15px;
    height: 15px;
    padding: 2px 5px;
    border-radius: 50%;
    background-color: #fc5252;
    top: 19px;
    right: 20px;
    position: absolute;
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
}
