.reviews {
  max-width: 1200px;
  margin: 0 auto;
}

.reviews_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  color: #222;
  margin-bottom: 30px;
}

.reviews_container {
  margin-bottom: 60px;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
}

.review_holder {
  padding: 30px;
}

.review_holder:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.review_upper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.review_profile {
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
    width: 50px;
    height: 50px;
    margin-right: 6px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: #222;
  }
}

.user_rank {
  position: absolute;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: -5px;
  left: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}

.review_info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .review_date {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #808080;
    margin-bottom: 19px;
  }

  .review_rating {
    display: flex;
    align-items: center;
  }
}

.review_msg {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: #222;
  word-break: break-all;
  white-space: pre-line;
}

.load_more_btn {
  margin: 0 auto;
  width: 172px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #fff;
  text-transform: uppercase;
}
