.application_content {
  margin: 100px auto 0;
  max-width: 1200px;
}

.application_container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  box-sizing: border-box;
  width: 100%;
  padding: 60px 40px 40px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;
}

.application_subtitle {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #222;
}

.application_par {
  margin-bottom: 60px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  text-align: left;
  color: #222;
}

.application_start {
  align-self: center;
  width: 444px;
  height: 58px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}
