.subscription_main {
  background: #f8f9fa;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

.subscription_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  margin: 60px auto;

  h1 {
    margin-bottom: 60px;
    padding-bottom: 20px;
    width: 100%;
    align-self: flex-start;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
    color: #222;
    border-bottom: 1px solid #ededed;
  }

  .subscription_table {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
    border: solid 1px #ededed;
    background-color: #fff;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 60px;

    thead {
      padding: 30px 40px;
      background: #f5f9ff;
      height: 75px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      td:first-child {
        text-align: left;
      }

      td:last-child {
        text-align: left;
      }
    }

    tr {
      display: grid;
      grid-template-columns: 30% 24% 24% auto;
      font-size: 14px;
      font-weight: 600;
      color: #222;
      td {
        padding: 29px 40px;
      }
    }
    tbody {
      tr {
        td {
          border-top: 1px solid #ededed;
        }
        td:first-child {
          text-align: left;
        }
        td:last-child {
          text-align: left;
        }
      }
    }

    .tr_nodata {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .td_nodata {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          font-weight: bold;
          color: #808080;
        }
      }
    }
  }

  p {
    color: #ff4b4b;
    font-size: 14px;
  }
  button {
    width: 444px;
    height: 58px;
    margin: 5px auto 141px;
    padding: 20px 61px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
    background-color: #0046a6;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: #fff;
    text-transform: uppercase;
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}
