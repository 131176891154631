.support_main {
  min-height: 85vh;
  background: #f6f7f9;
}

.support_container {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 120px;

  h1 {
    margin-top: 100px;
    margin-bottom: 80px;
    font-size: 30px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }

  hr {
    margin-block: 20px;
    border: 0.5px solid #ededed;
  }

  nav {
    height: 77px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    :first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    :last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  }

  .sub_category {
    height: 77px;
    display: grid;
    // grid-template-columns: repeat(6, 1fr);

    :first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    :last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  }

  .how_to_cards_container {
    display: flex;
    justify-content: space-between;
  }
}

.topics_section_wrapper {
  max-width: 1820px !important;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: minmax(auto, 260px) minmax(auto, 1200px) minmax(
      auto,
      260px
    );
  gap: 50px;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }

  .support_topics_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
}
