.upload_pattern {
  max-width: 1200px;
  margin: 100px auto;
}

.upload_container {
  display: flex;
  flex-direction: column;
  padding: 40px 0 60px;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
}

.upload_title_text {
  margin-bottom: 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;

  .title {
    font-size: 30px;
    font-weight: bold;
    color: #222;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #808080;
  }
}

.category_upper {
  display: flex;
  justify-content: space-between;
  padding-inline: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;

  .category_title {
    h2 {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #222;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      color: #808080;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: 10px 13px;
    border-radius: 8px;
    border: none;
    background: #ff6900;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;

    img {
      margin-right: 6px;
    }
  }
}

.upload_selectors {
  margin-top: 30px;
  padding-inline: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ededed;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
  row-gap: 20px;
}

.newpattern_form {
  max-width: 1120px;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
  margin: 40px auto 0;

  .form_subtitle {
    font-size: 14px;
    font-weight: bold;
    color: #222;
  }
}

.newpattern_title {
  width: 1120px;
  padding: 30px 40px;
  display: flex;
  align-items: center;

  .newpattern_name {
    font-size: 20px;
    font-weight: bold;
    color: #222;
    border: none;
    outline: none;
    margin-right: 10px;
    text-transform: capitalize;
  }

  .remove_btn {
    margin-right: auto;
  }

  .arrow_btn {
    transition: 0.3s ease-in-out;
  }

  button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
  }
}

.form_dnd_container {
  margin-inline: 40px;

  .form_subtitle {
    margin-bottom: 10px;
  }
}

.form_dnd {
  position: relative;
  max-width: 1040px;

  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
  }

  .dragdrop {
    width: 100%;
    padding: 40px;
    cursor: inherit;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      max-height: 185px;
      width: 100%;
      height: 100%;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: #808080;
      margin-bottom: 20px;
    }

    button {
      width: 114px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 29px;
      background: transparent;
      border: solid 1px #0046a6;
      font-size: 12px;
      font-weight: bold;
      color: #0046a6;
      text-transform: uppercase;
    }
  }
}

.dragdrop_filename {
  box-sizing: border-box;
  padding: 20px;
  // position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  // bottom: 0;
  background: #e5efff;

  .link_img {
    margin-right: 6px;
  }

  p {
    margin-right: auto;
    font-size: 12px;
    font-weight: 600;
    color: #222;
  }

  button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
  }
}

.upload_btn {
  width: 100%;
  margin: 30px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: bold;
  color: #0046a6;
  padding-bottom: 60px;
  border-bottom: 1px solid #ededed;

  img {
    margin-right: 6px;
  }
}

.coverage_container {
  margin-top: 40px;
  padding-inline: 40px;
  padding-bottom: 60px;
  border-bottom: 1px solid #ededed;

  .coverage {
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    max-height: 800px;
    transform: 'rotate(90deg)';
  }
}

.coverage_title {
  font-size: 14px;
  font-weight: bold;
  color: #222;
  margin-bottom: 4px;
}

.coverage_subtitle {
  display: block;
  margin-bottom: 60px;
  font-size: 12px;
  font-weight: 500;
  color: #808080;
  margin-bottom: 60px;
}

.approval_btn {
  margin: 60px auto 0;
  width: 444px;
  height: 58px;
  justify-self: center;
  border: none;
  border-radius: 30px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
