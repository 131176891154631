.filed_name {
  display: flex;
  justify-content: space-between;
  p:not(:first-child) {
    color: #ff4b4b;
  }
}

.label_input {
  width: 100%;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;

  .validation_warn {
    margin-bottom: 0;
    position: absolute;
    bottom: -19px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #ff4b4b;
  }

  p {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
  }

  select {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    height: 55px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #222;

    -webkit-appearance: none;
    appearance: none;

    background-image: url('../../../../img/icon_down1.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
  }

  select:focus {
    outline: 1px solid #0046a6;
  }
}
