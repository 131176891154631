.cart_section {
  max-width: 1200px;
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;

  hr {
    margin: 0;
    border: 0.5px solid #ededed;
  }
}

.cart_title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  h1 {
    margin-right: 6px;
    font-size: 30px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }

  .cart_count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fc5252;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
}

.cart_table {
  width: 100%;
  margin-top: 60px;
  padding-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;

  thead,
  tbody {
    display: flex;
    flex-direction: column;
    justify-content: center;

    tr {
      display: grid;
      grid-template-columns: 0.5fr 0.7fr 1.5fr  1fr 1fr;
      justify-items: center;
      align-items: center;

      .td_product {
        justify-self: start;
        cursor: pointer;
      }
    }
  }

  td {
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }

  thead {
    height: 77px;
    border-bottom: 1px solid #ededed;
    tr {
      height: inherit;

      .td_product {
        padding-left: 80px;
      }
    }
  }

  tbody {
    div {
      padding-block: 20px;
    }
    tr {
      padding-block: 20px;
      border-top: 1px solid #ededed;

      .td_product_img {
        justify-self: start;
        width: 128px;
        height: 114px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .td_product {
        font-size: 14px;
        font-weight: bold;
        color: #222;
        text-transform: uppercase;

        span {
          font-size: 14px;
          font-weight: 500;
          color: #808080;
        }
      }

      .td_quantity {
        .quantity_container {
          display: flex;
          align-items: center;
          height: 38px;
          border-radius: 20px;
          border: solid 1px #ededed;

          button {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            border: none;
            background: transparent;
            font-weight: 600;
            color: #222;
          }

          span {
            border-inline: 1px solid #ededed;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 100%;
          }
        }
      }
    }
  }
}

.cart_controls {
  margin-top: 20px;
  display: flex;
  gap: 10px;

  button {
    padding: 14px 20px;
    border-radius: 8px;
    border: solid 1px #808080;
    background-color: transparent;
    font-size: 12px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}

.link_disable {
  background-color: #cccccc !important;
  cursor: not-allowed;
}

.cart_order_link {
  align-self: center;
  max-width: 444px;
  margin-top: 80px;
  padding: 20px 130px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

.shop_empty_list {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 10px;
    width: 30px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #808080;
  }
}
