.menu_backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.menu {
  display: block;
  width: 300px;
  position: absolute;
  top: 87px;
  right: 10px;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
}

.menu_profile,
.menu_notifications,
.menu_list {
  padding: 20px;
}

.menu_profile {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile_pic {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  .profile_username {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: #222;
  }

  .profile_email {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #808080;
  }
}

.menu_notifications {
  text-decoration: none;
  display: flex;
  align-items: center;
  border-top: 1px solid #ededed;
  cursor: pointer;

  img {
    width: 13px;
    height: 15px;
    margin-right: 4px;
  }

  p {
    text-transform: uppercase;
    margin-right: auto;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    color: #222;
  }

  .notification_count {
    width: 23px;
    height: 23px;
    background-color: #fc5252;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.25;
      color: #fff;
    }
  }
}

.menu_list {
  border-top: 1px solid #ededed;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  color: #222;

  li {
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.menu_logout {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 58px;
  border: none;
  background-color: #0046a6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #fff;
  text-transform: uppercase;
}
