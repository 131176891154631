.main {
  background: #f8f9fa;
  min-height: 80vh;
  display: flex;
  justify-content: center;
}

.content {
  width: 1200px;
  margin-bottom: 150px;
}

.credit_history {
  height: 37px;
  margin: 100px 0px 81px 0px;
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
}

.my_credit {
  height: 24px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #222;
  span:first-child {
    text-transform: uppercase;
  }
  span:nth-child(2) {
    color: #0046a6;
  }
  span:last-child {
    text-transform: capitalize;
  }
}

.credit_history_status {
  display: flex;
  justify-content: flex-end;
}

.credit_table {
  width: 100%;
  border-radius: 10px;
  margin: 0px 0px 30px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  thead {
    padding: 30px 40px;
    background: #f5f9ff;
    height: 75px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  tr.no_data {
    border-top: 1px solid #ededed;
    td {
      border: none;
    }
  }
  tr {
    display: grid;
    grid-template-columns: 15% 15% 15% 20% 10% 10% 15%;
    // grid-template-columns: 15% 15% 25% 15% 15% 15%;
    align-items: center;
    height: 80px;
    font-size: 14px;
    font-weight: 600;
    color: #222;
    text-align: center;
    td {
      padding: 0;
      text-transform: capitalize;
    }
  }
  tbody {
    tr {
      border-top: 1px solid #ededed;
      td.deposit {
        color: #4bb3ff;
      }
      td.withdrawl {
        color: #ff4b4b;
      }
      td.credit_refund {
        button {
          border: none;
          background: transparent;
          color: #ff6900;
          font-weight: 600;
          text-transform: uppercase;
          text-decoration: underline;
          // display: none;
        }
      }
    }
  }
}

.shop_empty_list {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  img {
    margin-bottom: 10px;
    width: 30px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #808080;
  }
}
