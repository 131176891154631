.sell_container {
  max-width: 1200px;
  margin: 100px auto 0;

  hr {
    margin: 20px 0;
    border: 0.5px solid #ededed;
  }
}

.nav {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #efefef;
  border-radius: 10px;

  a {
    box-sizing: border-box;
    width: calc(100% / 6);
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  a:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  a:not(:last-child) {
    border-right: 1px solid #ededed;
  }
}
