.withdrawal_section {
  margin-top: 60px;
  margin-bottom: 140px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .withdrawal_content {
    padding: 60px 40px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
    border: solid 1px #ededed;
    background-color: #fff;
    h2 {
      height: 24px;
      font-size: 20px;
      font-weight: bold;
      color: #222;
      margin-bottom: 6px;
    }
    span {
      width: 100%;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: #222;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #222;
      margin-top: 50px;
      margin-bottom: 4px;
      &:not(:first-child) {
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      color: #808080;
      margin-bottom: 20px;
    }
    button {
      width: 166px;
      height: 43px;
      margin-top: 20px;
      margin-bottom: 80px;
      border-radius: 8px;
      border: solid 1px #0046a6;
      font-size: 12px;
      color: #0046a6;
      font-weight: bold;
      background-color: #fff;
    }

    .checkbox {
      display: flex;
      justify-content: center;
      label {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #222;
      }

      input[type='checkbox'] {
        cursor: pointer;
        appearance: none;
        box-sizing: border-box;
        margin: 0 6px 0 0;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        background: #fff;
      }

      input[type='checkbox']:checked {
        background: url('../../../../img/check.svg') no-repeat;
        background-position: center;
      }
    }

    .withdrawal_btn {
      width: 444px;
      height: 58px;
      margin: 60px auto 60px;
      padding: 20px 61px;
      margin-bottom: 40px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
      background-color: #0046a6;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.29;
      color: #fff;
      text-transform: uppercase;
    }
  }
}
