.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .modal_container {
    position: relative;
    width: 450px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 16px;
      font-weight: bold;
      color: #222;
      text-transform: uppercase;
      margin-bottom: 20px;
      margin-top: 40px;
    }
    p {
      margin-bottom: 58px;
      font-size: 14px;
      font-weight: 600;
      color: #222;
      text-align: center;

      padding: 0 31px;
    }

    .group_btn {
      width: 100%;
      padding: 20px 70px;
      display: flex;
      justify-content: center;
      border-top: 1px solid #ededed;
      button {
        width: 150px;
        height: 42px;
        padding: 12px 40px;
        border-radius: 29px;
        border: none;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .btn_confirm {
        margin-left: 10px;
        background-color: #0046a6;
        color: #fff;
      }
    }
  }
}
