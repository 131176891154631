.dropdown_container {
  box-sizing: border-box;
  max-width: 260px;
  display: flex;
  flex-direction: column;
}

.dropdown {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: 1px solid #ededed;
  background-color: #fff;
}

.dropdown:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdown_title {
  display: flex;

  button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: none;
  }

  span {
    text-transform: capitalize;
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: #222;
  }

  img {
    transition: 0.3s ease-in-out;
  }
}

.dropdown_list {
  animation: showBlock 0.3s;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dropdown_item {
  label {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  input[type='checkbox'] {
    cursor: pointer;
    appearance: none;
    box-sizing: border-box;
    margin: 0 6px 0 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    background: #fff;
  }

  input[type='checkbox']:checked {
    background: url('../../../img/check.svg') no-repeat;
    background-position: center;
  }
}

@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
