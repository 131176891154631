.main {
  background: #f8f9fa;
  min-height: 80vh;
}

.content {
  max-width: 1200px;
  margin: 100px auto 140px;

  .upper_hr {
    border: 0.5px solid #ededed;
  }

  .sub_title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
}

.cards_container {
  margin-top: 60px;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
  h4 {
    font-size: 14px;
    font-weight: 600;
    color: #222;
    margin-top: 10px;
    cursor: pointer;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    color: #808080;
    margin-top: 4px;
  }
}

.project_controls {
  margin-top: 30px;
  display: flex;
  gap: 20px;

  button {
    padding: 14px 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .controls_open_in_editor {
    border-radius: 8px;
    border: solid 1px #222;
    background: transparent;
    color: #222;
    &:disabled {
      background: #cccccc;
      cursor: default;
      border: none;
      color: #fff;
    }
  }

  .controls_delete {
    padding: 14px 20px;
    border-radius: 8px;
    background-color: #ff5252;
    border: none;
    color: #fff;
    &:disabled {
      background-color: #cccccc;
      cursor: default;
    }
  }
}
