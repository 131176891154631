.uploader_section {
  position: relative;
  background-color: #f8f9fa;
  min-height: 85vh;
  background-image: url('../../img/uploaderPageBanner.png');
  background-size: auto 500px;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}

.uploader_profile {
  position: absolute;
  margin: 0 auto 100px;
  left: 0;
  right: 0;
  top: 330px;
  max-width: 1200px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
}

.profile_container {
  box-sizing: border-box;
  padding: 40px;
  width: 100%;

  hr {
    border: 0.5px solid #ededed;
  }
}

.profile_subtitle {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #222;
}

.profile_upper,
.profile_middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile_upper {
  margin-bottom: 30px;
}

.profile_img {
  position: relative;
  width: 70px;
  height: 70px;
  margin-right: 10px;

  .pfp {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .rank {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.profile_info {
  display: flex;
  flex-direction: column;
  margin-right: auto;

  p {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.23;
    color: #222;
    margin-bottom: 4px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #808080;
  }
}

.profile_uploads {
  margin-right: 30px;
}

.profile_uploads,
.profile_downloads {
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #222;
  }
}

.profile_middle {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;

  li {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
      margin-bottom: 10px;
    }

    h4 {
      margin-bottom: 4px;
    }
  }

  .profile_company,
  .profile_email {
    padding-left: 30px;
    border-left: 1px solid #ededed;
  }
}

.profile_lower {
  margin-top: 30px;

  h4 {
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #222;
  }
}

.uploader_selector {
  max-width: 1200px;
  margin: 858px auto 65px;
}

.patterns_list {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
