.pattern_filter {
  margin-bottom: 60px;
  height: 61px;
  display: flex;
  box-shadow: 0 8px 30px 0 rgba(174, 175, 182, 0.11);
  background-color: #fff;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  .title {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #ededed;
    height: 100%;
    img {
      width: 16px;
      height: 18px;
      object-fit: contain;
      margin-right: 6px;
    }
  }
  .select_list {
    display: flex;
    align-items: center;

    label {
      display: flex;
      flex-direction: column;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        color: #222;
        text-transform: capitalize;
      }

      select,
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        min-width: 170px;
        border: none;
        box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
        border-right: solid 1px #ededed;
        background-color: #fff;
      }

      button {
        height: 61px;

        img {
          width: 8px;
          height: 6px;
          transform: rotate(180deg);
        }
      }

      select {
        position: absolute;
        z-index: 150;
        margin-top: 5px;
        top: 135px;
        appearance: none;
        outline: none;
        overflow: hidden;

        option {
          text-transform: capitalize;
          cursor: pointer;
        }

        option:hover {
          color: #0046a6;
        }

        option:active {
          background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
        }
      }
    }
  }
  .button_group {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      height: 61px;
      border: none;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      img {
        margin-right: 6px;
      }
      &:last-child {
        background-color: #0046a6;
        color: #fff;
        width: 161px;
      }
    }
  }
}
