.info_container {
  padding: 60px;
  margin-top: 40px;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;

  img {
    object-fit: contain; 
    max-width: 100%
  }
}
