.card {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  width: 380px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;

  .card_sponsored {
    position: absolute;
    left: 0;
    top: 0;

    img {
      max-width: 100px;
    }
  }

  .upper {
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .sponsor_period,
    .upload_date {
      margin-right: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      color: #222;
    }

    .add_sponsor {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 100%;
      border-radius: 18px;
      background-color: #0046a6;

      border: none;

      img {
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }

      p {
        font-size: 12px;
        font-weight: bold;
        color: #fff;
      }
    }

    .marked {
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: none;

      img {
        width: 16px;
        height: 20px;
      }
    }
  }

  .card_pattern_button {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
    background: transparent;
    margin-bottom: auto;
  }

  .card_pattern_container {
    width: 100%;
    height: 248px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .card_pattern {
    max-width: 285px;
    cursor: pointer;
  }

  .card_text {
    align-self: flex-start;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    max-width: 340px;
    margin-bottom: auto;
  }

  .card_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    text-align: left;
    color: #222;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .card_subtitle {
    font-size: 12px;
    font-weight: 600;
    color: #808080;
    text-transform: uppercase;
  }

  .card_info {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card_rating {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 2px;
    }

    .card_details {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .card_price {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.22;
        color: #0046a6;
      }

      .card_downloads {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.25;
        color: #808080;
      }
    }
  }

  .card_creditsto {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    border: none;
    background: transparent;
  }
}

.card_author {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 4px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #222;
  }
}

.card_rank {
  display: flex;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    color: #222;
    text-transform: capitalize;
  }
}

.upper_hr,
.lower_hr {
  position: relative;
  margin-top: 0;
  width: calc(100% - 2px + 40px);
  border: 0.5px solid #ededed;
}

.upper_hr {
  margin-bottom: 10px;
}

.lower_hr {
  margin-bottom: 12px;
}

.card_menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(34, 34, 34, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    width: 182px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
  }

  .menu_stop_selling_btn {
    width: 150px;
    height: 42px;
    border-radius: 21px;
    background-color: #fff;
    color: #111;
  }

  .menu_editor_btn {
    background-color: #0046a6;
  }

  .menu_delete_btn {
    background-color: #ff5252;
  }
  p:nth-child(2) {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #fc5252;
    margin-top: 10px;
  }
  p:last-child {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-top: 20px;
  }
}

.card_project_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;

  .card_project_info_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: block;
      color: #222;
    }

    .card_project_info_title {
      width: 130px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.29;
      color: #222;
      padding-right: 10px;
      border-right: 1px solid #ededed;
    }

    .card_project_info_value {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      text-align: right;
    }
  }
}

.card_project_uploader_info {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card_project_user_rank {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -5px;
    left: 20px;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
