.selector_label {
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  button {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
    height: 55px;

    span {
      font-size: 13px;
      font-weight: 500;
      color: #222;
      text-transform: capitalize;
    }
  }

  select,
  option {
    font-size: 13px;
    font-weight: 500;
    color: #222;
    text-transform: capitalize;
  }

  select {
    position: absolute;
    left: 0;
    top: 85px;
    z-index: 100;
    width: 100%;
    max-height: 339px;
    -ms-overflow-style: scrollbar;
    appearance: none;
    padding: 20px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
    outline: none;
    overflow: scroll;
  }

  select::-webkit-scrollbar {
    display: none;
  }

  option {
    cursor: pointer;
  }

  option:not(:last-child) {
    margin-bottom: 10px;
  }

  option:hover {
    color: #0046a6;
    background-color: #fff;
  }

  option:active {
    background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
  }
}
