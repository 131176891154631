.form_descr {
  margin-top: 40px;
  margin-inline: 40px;

  p {
    margin-bottom: 10px;
  }

  textarea {
    width: 100%;
    max-height: 116px;
    -ms-overflow-style: scrollbar;
    height: 116px;
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
    resize: none;
  }
}
