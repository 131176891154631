.banner {
  width: 100%;
  background-color: #111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  // background: url('../../img/banner.png') no-repeat;
  // background-size: cover !important;
  // background-position: center !important;
}

.title {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.23;
  color: #fff;
  text-transform: uppercase;
}

.start_btn {
  margin-bottom: 50px;
  align-self: center;
  background-color: transparent;
  width: 156px;
  height: 46px;
  border-radius: 27px;
  border: solid 1px #f7f7f7;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
