.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal_content {
  width: 580px;
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
}

.modal_head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  background: #222;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

.modal_body {
  background: #f6f7f9;
  max-height: 470px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px 35px 20px;

  p {
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }

  textarea {
    border: 1px solid #ededed;
    border-radius: 10px;
    height: 140px;
    margin-top: 10px;
    padding: 20px;
    width: 100%;
  }
}

.modal_footer {
  background: #f6f7f9;
}

.modal_btns {
  padding-block: 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-top: 1px solid #ededed;

  button {
    width: 150px;
    height: 42px;
    border-radius: 29px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .btn_close {
    text-transform: uppercase;
    background: transparent;
    border: solid 1px #222;
    color: #222;
  }
  .btn_confirm {
    text-transform: uppercase;
    background-color: #0046a6;
    border: none;
    color: #fff;
  }
}
