.support_link_card_container {
  max-width: 390px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 65px 30px 65px;
  width: 380.64px;
  row-gap: 12px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
  cursor: pointer;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #222;
  }
}
