.shop_hero {
  width: 100%;
  height: 500px;

  h1 {
    padding-top: 232px;
    max-width: 390px;
    margin-inline: auto;
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.16);
    font-size: 40px;
    font-weight: 800;
    line-height: 0.95;
    letter-spacing: 4px;
    color: #fff;
    text-transform: uppercase;
  }
}
