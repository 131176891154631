.inquiry_table {
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;

  thead,
  tbody {
    display: flex;
    flex-direction: column;
    justify-content: center;

    tr {
      display: grid;
      grid-template-columns: 0.5fr 2fr 1fr 1fr 0.5fr;
      align-items: center;

      td {
        font-size: 14px;
        font-weight: bold;
        color: #222;
      }

      .td_earnings {
        justify-self: end;
      }
    }
  }

  .table_head {
    padding: 30px 40px;
    background: #f5f9ff;
    height: 75px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    tr {
      justify-items: center;
    }
  }

  .table_body {
    padding-top: 0;
    background: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    tr {
      padding: 30px 40px;
      border-top: 1px solid #ededed;

      .td_date {
        max-width: 90px;
      }

      .td_nickname,
      .td_area,
      .td_asset {
        justify-self: center;
      }

      .td_area {
        white-space: pre-wrap;
        text-align: center;
      }

      .td_earnings {
        color: #4bb3ff;
      }

      .td_asset {
        max-width: 310px;

        h4,
        p {
          text-transform: uppercase;
        }

        h4 {
          color: #222;
        }

        p {
          font-size: 12px;
          font-weight: 600;
          line-height: 1.83;
          color: #808080;
        }
      }
    }

    .tr_nodata {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .td_nodata {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          font-weight: bold;
          color: #808080;
        }
      }
    }
  }
}
