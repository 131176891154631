.payout_container {
  max-width: 1200px;
  margin: 60px auto 140px;
}

.payout_title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.payout_form {
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;

  hr {
    margin: 0;
    border: 0.5px solid #ededed;
  }
}

.form_content {
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.form_subtitle {
  font-size: 14px;
  font-weight: bold;
  color: #222;
}

.form_balance {
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  .balance_title {
    border-right: 1px solid #ededed;
    padding-right: 10px;
    margin-right: 10px;
  }

  .balance_amount {
    font-size: 20px;

    span {
      color: #0046a6;
    }
  }
}

.form_label {
  font-size: 14px;
  font-weight: bold;
  color: #222;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 10px;
  }

  input {
    width: 420px;
    height: 55px;
    margin-right: 10px;
    padding: 19px 20px 18px 20px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;

    font-size: 14px;
    font-weight: 500;
    color: #222;
    outline: none;
  }

  input:focus {
    outline: 1px solid #0046a6;
  }
}

.form_amount_label {
  margin-bottom: 20px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.form_amount {
  padding-left: 18px;
  width: 420px;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
  display: flex;
  align-items: center;

  span {
    width: 10px;
    display: block;
  }

  input {
    width: 400px;
    border: none;
    outline: none;
  }

  input:focus {
    outline: none;
  }
}

.form_email_container {
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-right: 10px;
  }

  .form_emailtag,
  .form_emailtag_direct {
    width: 250px;
  }

  .form_emailtag_direct {
    background-image: url('../../../../../img//dropdown_arrow.svg');
    background-repeat: no-repeat;
    background-position: 95%;
  }
}

.form_remaining {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #0046a6;
}

.form_checkbox_label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #222;
}

.form_descr {
  max-width: 890px;
  font-size: 14px;
  font-weight: 500;
  color: #808080;
}

.form_submit_content {
  padding-top: 0;
  position: relative;

  .form_validation {
    position: absolute;
    top: 22px;
    font-size: 12px;
    font-weight: 600;
    color: #ff4b4b;
  }

  .form_submit_btn {
    margin-top: 60px;
    width: 444px;
    height: 58px;
    border: none;
    border-radius: 30px;
    box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
    background-color: #0046a6;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
}

input[type='checkbox'] {
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  margin: 0 6px 0 0;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  background: #fff;
}

input[type='checkbox']:checked {
  background: url('../../../../../img/check.svg') no-repeat;
  background-position: center;
}
