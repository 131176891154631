.terms_and_policy_section {
  min-height: 85vh;
  background: #f6f7f9;
}

.terms_and_policy_container {
  padding-top: 100px;
  max-width: 1200px;
  margin: 0 auto 100px;
}

.terms_and_policy_title {
  font-size: 30px;
  font-weight: bold;
  color: #222;
  margin-bottom: 30px;
}

.terms_and_policy_subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
  margin-bottom: 40px;
}

.nav {
  margin-bottom: 40px;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: solid 1px #efefef;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #808080;
  }

  .nav_item1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
  }

  .nav_item2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
  }
}

.terms_and_policy_outer {
  width: 100%;
  padding: 20px 40px 20px 40px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;

  min-height: 400px;
  max-height: 800px;
  overflow-y: scroll;
}
.terms_and_policy_outer::-webkit-scrollbar {
  width: 4px;
  margin-block: 10px;
  writing-mode: horizontal-tb;
}

.terms_and_policy_outer::-webkit-scrollbar-track,
.terms_and_policy_outer::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

.terms_and_policy_outer::-webkit-scrollbar-track {
  margin-block: 20px;

  background-color: #ededed;
}

.terms_and_policy_outer::-webkit-scrollbar-thumb {
  background-color: #222;
}

.terms_and_policy_inner {
  span {
    color: #222;
  }

  p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    line-height: 1.4;
    margin: 1rem;
  }
}
