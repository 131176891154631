.filter_container {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_selected {
  font-size: 14px;
  font-weight: 600;
  color: #222;
}

.filter_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 46px;

  label {
    height: fit-content;
  }

  button,
  select,
  option {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  button,
  select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 189px;

    border: 1px solid #ededed;
    background-color: #fff;
    border-radius: 8px;
  }

  button {
    height: 100%;
    padding: 20px;
    img {
      transition: all 0.3s ease-in-out;
    }
  }

  select {
    position: relative;
    z-index: 150;
    margin-top: 5px;
    appearance: none;
    outline: none;
    overflow: hidden;
    height: fit-content;
    height: 174px;
    option {
      padding: 20px 20px 0 20px;
      cursor: pointer;
      &:last-child {
        padding-bottom: 20px;
      }
    }

    option:hover {
      color: #0046a6;
    }

    option:active {
      background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
    }
  }
}
