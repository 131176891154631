.films {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #f8f9fa;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  text-align: center;
  color: #222;
  margin-bottom: 60px;
  white-space: pre-wrap;
}

.cards_container {
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
}

.film_load_more {
  margin-top: 60px;
  width: 172px;
  height: 58px;
  padding: 20px 40px;
  border-radius: 100px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.29;
  color: #fff;
  border: none;
}
