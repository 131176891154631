.container {
  max-width: 100%;
  margin: 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upper {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.logo {
  margin-right: max(calc((100vw - 1200px) / 2 - 147px), 11.1%);
  height: 40px;
}

.nav {
  a {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    text-decoration: none;
  }

  a:not(:last-child) {
    margin-right: 50px;
  }

  margin-right: auto;
}

.socials {
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;

  a {
    display: block;
    width: 36px;
    height: 36px;
  }

  .socials_inst {
    background: url('../../img/icon_instagram.svg');
  }

  .socials_yt {
    background: url('../../img/icon_youtube.svg');
  }

  .socials_fb {
    background: url('../../img/icon_facebook.svg');
  }
}

.footer_hr {
  width: calc(100% - 2px);
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #ededed;
  border: 1px solid #ededed;
}

.lower {
  width: 100%;
  height: 16px;
  display: flex;
  align-items: center;
  // justify-content: space-between;

  .footer_contacts_container {
    display: flex;
    flex-direction: column;
  }

  small {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: #222;
  }

  .footer_tel {
    margin-left: auto;
  }

  .footer_terms_policy {
    margin-right: max((100vw - 1200px) / 2 - 284px, 3.66%);
    display: flex;
    column-gap: 40px;

    a {
      font-size: 11px;
      font-weight: 600;
      color: #808080;
    }
  }
}

.footer_tel {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  color: #222;
}

.email {
  margin-left: 10px;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 16px;
    margin-right: 6px;
  }

  span {
    display: block;
    margin-right: 5px;
  }

  span,
  a {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    color: #222;
  }

  a {
    text-decoration: none;
  }
}
