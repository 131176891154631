.item_container {
  cursor: pointer;
  padding: 30px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
  display: flex;
  align-items: center;

  .item_pattern {
    width: 160px;
    height: 82px;
    margin-right: 35px;
  }

  .item_content {
    margin-right: auto;

    .item_date {
      margin-bottom: 4px;
      font-size: 12px;
      font-weight: 500;
      color: #222;
    }

    .item_title {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.57;
      color: #222;
    }

    .item_rating {
      display: flex;
      gap: 2px;
      margin-bottom: 25px;
    }

    .item_stats {
      width: 100%;
      display: flex;
      column-gap: 40px;

      li {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        color: #222;

        p {
          font-weight: 600;
          padding-right: 10px;
          border-right: 1px solid #ededed;
          margin-right: 10px;
        }
      }
    }
  }

  .item_review_btn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    img {
      margin-right: 6px;
    }
  }
}
