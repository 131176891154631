.popup_container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .popup_modal {
    // width: 412px;
    // height: 641px;
    background: #f6f7f9;

    .popup_head {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #222;
      height: 52px;
      padding-right: 30px;

      button {
        background: none;
        border: none;
        padding: 0;
      }
    }

    .popup_ad {
      // margin-bottom: 20px;
      // padding: 30px;
      border-bottom: 1px solid #ededed;

      .popup_ad_container {
        display: block;
        background-color: #fff;
        // width: 352px;
        // height: 470px;
        border-bottom: 1px solid #ededed;
        padding: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .popup_show {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      label {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: block;
          font-size: 14px;
          font-weight: 500;
          color: #222;
        }
      }
    }
  }
}
