.container {
  margin: 100px auto 0;
  max-width: 1200px;
}

.title {
  margin-bottom: 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
}

.newcar_form {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
}

.newcar_subtitle {
  margin-top: 40px;
  padding-inline: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #222;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
}

.form_inputs {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
  row-gap: 20px;
  align-items: center;
  padding: 0 40px 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ededed;
}

.input_label {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .input {
    padding: 20px;
    height: 55px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.register_btn {
  margin-top: 40px;
  margin-bottom: 40px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  align-self: center;
  width: 444px;
  height: 58px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}

.register_pattern_container {
  padding: 0 40px 0;
}
