.personal_section {
  min-height: 85vh;
  background: #f6f7f9;
}

.personal_container {
  padding-top: 100px;
  max-width: 1200px;
  margin: 0 auto;
}

.personal_title {
  height: 37px;
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
}
hr {
  margin: 20px 0;
  border-color: #f8f9fa;
  border-bottom: none;
}
.nav {
  width: 100%;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: solid 1px #efefef;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: none;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: none;
    }
  }

  a .active {
    background-color: #0046a6;
    color: #fff;
  }
}
