.notification_container {
  display: flex;
  flex-direction: column;
}

.notification_upper {
  cursor: pointer;
  padding: 24px 40px;
  display: flex;
  align-items: center;
  //   border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;

  .notification_title {
    margin-right: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ededed;

    .notification_details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3px;

      .notification_details_status {
        color: red;
      }
    }

    span {
      display: block;
      font-size: 12px;
      font-weight: 500;
      color: #222;
      margin-bottom: 6px;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #222;
    }
  }

  .upper_descr {
    margin-right: auto;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  button {
    background: transparent;
    border: none;

    img {
      transition: all 0.5s ease-in-out;
    }
  }
}

.notification_lower {
  padding: 30px 40px;
  border: 1px solid #ededed;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
}
