.search {
  top: -38px;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1200px;
  height: 75px;
  padding: 20px;
  margin-inline: auto;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.2);
  background-color: #fff;

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }

  input {
    width: 80%;
    border: none;
    margin-right: auto;
    font-size: 12px;
    font-weight: bold;
    color: #222;
    outline: none;
  }

  input::placeholder {
    color: #808080;
  }

  button {
    border: none;
    width: 92px;
    height: 35px;
    padding: 10px 20px;
    border-radius: 18px;
    background-color: #0046a6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}
