.find_pwd_link {
  text-decoration: none;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 60px;
  border: none;
  background-color: transparent;
  align-self: flex-end;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  text-align: left;
  color: #808080;
}

.email_label {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pwd_label {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.email_label,
.pwd_label {
  box-sizing: border-box;
  border: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);

  p {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    text-align: left;
    color: #808080;
  }

  input {
    margin-left: auto;
    width: 67.8%;
    height: 15px;
    border-left: 1px solid #ebebeb;
    border-right: none;
    border-top: none;
    border-bottom: none;
    outline: none;
    padding-left: 20px;
    padding-right: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: left;
    color: #222;
  }

  input[type='password'] {
    font-family: Verdana;
    letter-spacing: 4px;
  }

  input[type='password']::-ms-reveal {
    display: none;
  }

  input[type='password']::-ms-clear {
    display: none;
  }

  input:focus {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  img {
    opacity: 0.7;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    right: 20px;
  }
}

.divider {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  hr {
    box-sizing: border-box;
    margin: 0;
    height: 1px;
    border: 1px solid #ededed;
    border-radius: 1px;
    background-color: #ededed;
    width: 44%;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    color: #808080;
  }
}

.signin_with {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  background-color: transparent;
  width: 100%;

  :first-child {
    margin-left: 30px;
    margin-right: 10px;
  }

  :last-child {
    margin-right: 30px;
  }

  span {
    margin-right: auto;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    color: #222;
  }
}

.signin_google {
  border: 1px solid #e74133;
  margin-bottom: 10px;
}

.signin_fb {
  border: 1px solid #3a589e;
  margin-bottom: 40px;
}

.sign_up {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: #808080;

  a {
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.25;
    color: #ff6900;
  }
}
