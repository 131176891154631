.pricing_section {
  min-height: 85vh;
  background: #f6f7f9;
}

.pricing_container {
  padding-top: 103px;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing_title {
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.nav {
  margin-bottom: 60px;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: solid 1px #efefef;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #808080;
  }

  .nav_packs {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
  }

  .nav_subscription {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
  }

  a .active {
    background-color: #0046a6;
    color: #fff;
  }
}

.paypal {
  width: 217px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  box-sizing: border-box;
  width: 100%;
  padding: 60px 30px;
  background: #fff;
  border-radius: 10px;
  border: solid 1px #ededed;
  margin-bottom: 141px;
  display: flex;
  flex-direction: column;
}

.content_title {
  font-size: 22px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  margin-bottom: 30px;
}

