.inquiry_form {
  width: 100%;
  height: 83px;
  margin-bottom: 40px;
  padding: 20px 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-top: 1px solid #222;
}

.inquiry_filter {
  display: flex;
  margin-right: auto;
  width: 38.4%;

  .filter_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 43px;
    font-size: 12px;
    font-weight: 600;
    color: #333;
    background: transparent;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
  }

  .filter_item:not(:first-child) {
    border-left: none;
  }

  .filter_item:first-child {
    border-left: 1px solid #ededed;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .filter_item:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .active {
    background-color: #0046a6;
    color: #fff;
  }
}

.inquiry_date {
  position: relative;
  height: 43px;
  margin-right: 20px;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 179px;
    padding: 14px 20px;
    height: 100%;
    border: 1px solid #ededed;
    border-radius: 8px;
    background: #fff;

    input {
      max-width: 100px;
      border: none;
      background: transparent;
    }

    input:focus {
      outline: none;
    }
  }

  span {
    margin-inline: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../../../../../img/icon_calendar.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.inquiry_submit {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  background-color: #0046a6;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.reset_button {
  margin-left: 20px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  background-color: #ededed;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #222;
  color: #222;
  text-transform: uppercase;
}
