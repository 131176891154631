#App {
  min-width: 1920px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f8f9fa;
}

#header {
  background: #fff;
}

#footer {
  background: #fff;
  height: 137px;
  margin-top: auto;
}
