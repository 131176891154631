.collection_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .collection_layer {
    position: absolute;
    height: 10%;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.4);

    background-color: #fff;
    border-radius: 10px;
  }
}
