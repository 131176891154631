.filter_container {
  height: fit-content;
  padding: 40px 0 30px 30px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  h3 {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #222;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      cursor: pointer;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #222;
      }

      input[type='checkbox'] {
        cursor: pointer;
        appearance: none;
        box-sizing: border-box;
        margin: 0 6px 0 0;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        background: #fff;
      }

      input[type='checkbox']:checked {
        background: url('../../../img/check.svg') no-repeat;
        background-position: center;
      }
    }

    .depth2_categories {
      margin-left: 18px;
    }

    .depth3_categories {
      margin-left: 36px;
    }
  }
}
