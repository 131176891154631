.notifications_main {
  background-color: #f8f9fa;
  min-height: 85vh;
}

.notifications_content {
  max-width: 1200px;
  margin: 100px auto 0;

  h1 {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ededed;
    font-size: 30px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }
}

.notifications_holder {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 60px;
}
