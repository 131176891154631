.sales_info_container {
  max-width: 1200px;
  height: 150px;
  padding-inline: 40px;
  margin: 60px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;

  .info_item {
    padding-right: 40px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item_content {
      width: 50%;
      position: relative;
      display: flex;
      flex-direction: column;

      img {
        width: 20px;
        height: 20px;
        margin-bottom: 10px;
      }

      span {
        font-size: 14px;
        font-weight: bold;
        color: #222;
      }

      button {
        position: absolute;
        padding: 0;
        bottom: -20px;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: #808080;

        img {
          width: 5px;
          height: 10px;
          margin-bottom: 0;
          margin-left: 6px;
        }
      }
    }

    .item_amount {
      font-size: 20px;
      font-weight: bold;
      color: #0046a6;
    }
  }

  .info_item:not(:last-child) {
    margin-right: 40px;
    border-right: 1px solid #ededed;
  }
}

.inquiry_container {
  max-width: 1200px;
  margin: 60px auto 140px;
}

.inquiry_title {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  margin-bottom: 20px;
}
