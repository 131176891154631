.subs_conainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 60px;
}

.sub_subscription {
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  height: 118px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
}

.sub_subscription:hover {
  border: solid 1px #0046a6;
}

.trial_label {
  display: flex;
  align-items: center;
  margin-right: auto;
  font-size: 16px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;

  input[type='radio'] {
    cursor: pointer;
    appearance: none;
    box-sizing: border-box;
    margin: 0 6px 0 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    background: #fff;
  }

  input[type='radio']:checked {
    background: url('../../../img/check.svg') no-repeat;
    background-position: center;
  }
}

.total {
  font-size: 12px;
  font-weight: 600;
  color: #222;
  margin-right: 60px;
  text-transform: capitalize;
}

.price_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .price {
    font-size: 50px;
    font-weight: bold;
    color: #ff6900;

    span {
      font-size: 16px;
    }
  }

  .price_descr {
    font-size: 10px;
    font-weight: 600;
    color: #808080;
  }
}

.box {
  width: 217px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 55px;
  min-height: 55px;
  button:disabled {
    width: 100%;
    cursor: not-allowed;
  }
}

.paypal {
  &_logo {
    font-family: Verdana, Tahoma;
    font-weight: bold;
    font-size: 26px;
    i:first-child {
      color: #253b80;
    }

    i:last-child {
      color: #179bd7;
    }
    color: #fff;
  }

  &_button {
    padding: 15px 30px;
    // border: 1px solid #ff9933;
    border-radius: 5px;
    // background-image: linear-gradient(#fff0a8, #f9b421);
    border: none;
    background-color: #0070ba;
    margin: 0 auto;
    display: block;
    width: 150px;
    border-radius: 28px;
    position: relative;

    &_title {
      font-size: 14px;
      color: #505050;
      vertical-align: baseline;
      text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
    }

    .paypal_logo {
      display: inline-block;
      text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
      font-size: 20px;
    }
  }
}
