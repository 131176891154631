.pattern_details {
  // padding-top: 60px;
  padding-bottom: 87px;
  background: #f8f9fa;
  min-height: 85vh;
}

.details_container {
  max-width: 1200px;
  margin: 0 auto 100px;

  hr {
    border: 0.5px solid #ededed;
    margin-bottom: 30px;
  }
}

.details_title {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    color: #808080;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.23;
    color: #222;
    text-transform: capitalize;
  }
}

.details_rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  margin-bottom: 30px;
}

.details_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  :nth-child(n) {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .info_profile {
    margin-right: auto;
    border: none;
    background: transparent;

    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;
      object-fit: cover;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.29;
      color: #222;
    }
  }

  .info_reviews {
    margin-right: 60px;
  }

  .info_reviews,
  .info_downloads {
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.29;
      color: #222;
    }
  }
}

.card_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.details_pattern,
.details_descr {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
  max-height: 591px;
}

.details_pattern {
  position: relative;
  width: 666px;

  .pattern_sponsored {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100px;
    max-height: 100px;

    img {
      width: 100%;
    }
  }

  .details_pattern_img_container {
    height: 100%;
    margin-right: 10px;
    overflow: hidden;
    .details_pattern_img {
      width: 100%;
      height: 100%;
      // object-fit: contain;
    }
  }

  .pattern_info {
    margin-top: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .pattern_upload {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      color: #222;
      margin-right: 10px;
    }

    .pattern_marked {
      border: none;
      background: #ededed;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;

      img {
        width: 16px;
        height: 20px;
      }
    }
  }
}

.details_descr {
  position: relative;
  box-sizing: border-box;
  width: 504px;
  padding: 30px;

  hr {
    margin: 0;
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    border: 0.5px solid #ededed;
  }

  .descr_title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: #222;
  }

  .descr_content {
    height: 190px;
  }

  ul {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #222;

    li:not(:last-child) {
      margin-bottom: 14px;
    }

    li:last-child {
      margin-bottom: 20px;
    }
  }

  .descr_credit {
    align-self: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: #0046a6;
  }

  .descr_tags {
    margin-bottom: 57px;

    .descr_title {
      margin-top: 30px;
    }

    .tags_container {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      button {
        height: 27px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.25;
        color: #ff6900;
        background: transparent;
        border-radius: 4px;
        border: solid 1px #ff6900;
      }
    }
  }

  .descr_editor_btn {
    margin-top: 30px;
    width: 100%;
    height: 58px;
    border-radius: 30px;
    box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
    background-color: #0046a6;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: #fff;
    text-transform: uppercase;
    border: none;
  }
}
