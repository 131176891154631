.history_container {
  margin: 60px auto 140px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.history_title {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
}

.history_table {
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;
  margin-bottom: 30px;

  tr {
    display: grid;
    grid-template-columns: 1fr 2.5fr 1fr 1fr 1fr;
    // grid-template-columns: 1fr 2.5fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    padding: 30px 40px;

    td {
      font-size: 14px;
      font-weight: bold;
      color: #222;
    }
  }

  thead,
  tbody {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  thead {
    background: #f5f9ff;
    height: 75px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  tbody {
    tr {
      border-top: 1px solid #ededed;
    }

    .td_category {
      text-transform: capitalize;
    }

    .td_deposit {
      color: #4bb3ff;
    }

    .td_withdrawal {
      color: #ff4b4b;
    }
  }

  .tr_nodata {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .td_nodata {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #808080;
      }
    }
  }
}
