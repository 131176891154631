.pattern_page_content {
  padding-top: 177px;
  padding-bottom: 140px;
  background: #f8f9fa;
}

.pattern_title {
  max-width: 1200px;
  margin: 0 auto 24px auto;
  display: flex;
  align-items: center;

  .pattern_categories {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-left: auto;

    label {
      display: flex;
      align-items: center;

      span {
        text-transform: capitalize;
      }

      input {
        outline: none;
      }
    }
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.23;
    color: #222;
  }

  label,
  select,
  option,
  button {
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  .pattern_dd_filter {
    margin-left: 40px;
    box-sizing: border-box;
    position: relative;
    width: 200px;
    height: 46px;
    padding: 14px 20px;
    border-radius: 8px;
    border: 1px solid #ededed;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100%;
      background: transparent;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      transition: 0.3s ease-in-out;
    }

    select {
      z-index: 1000;
      top: 56px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 134px;
      appearance: none;
      outline: none;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #ededed;
      background-color: #fff;
      overflow: hidden;

      option {
        cursor: pointer;
      }

      option:hover {
        color: #0046a6;
      }

      option:active {
        background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
      }

      option:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}

.patterns_list {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
