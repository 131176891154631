.email_label {
  margin-bottom: 30px;
}

.code_label {
  margin-bottom: 80px;
}

.email_label,
.code_label {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #222;

  p {
    margin-bottom: 10px;
  }

  input {
    box-sizing: border-box;
    padding-left: 20px;
    height: 55px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
    border: solid 1px #ededed;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #222;
  }

  input:focus {
    outline: 1px solid #0046a6;
  }
}

.input_btn {
  position: absolute;
  bottom: 17px;
  right: 20px;
  border-radius: 14px;
  border: none;
  background-color: #0046a6;
  height: 21px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  text-transform: uppercase;
}

.btn_verify:disabled {
  background: #d3d3d3;
  color: #222;
}
