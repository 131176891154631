.main {
  background: #f8f9fa;
  min-height: 80vh;
  display: flex;
  justify-content: center;
}

.content {
  width: 1200px;
  margin-bottom: 100px;
}

.shop_coupon {
  height: 37px;
  margin: 100px 0px 20px 0px;
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
}

.my_shop_coupon_history {
  height: 24px;
  margin: 60px 0px 20px 0px;
  font-size: 20px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
}

.shop_coupon_state {
  display: flex;
  justify-content: flex-end;
}

.coupon_table {
  width: 100%;
  border-radius: 10px;
  margin: 0px 0px 30px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;

  thead {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f5f9ff;
  }

  tr {
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    font-size: 14px;
    font-weight: 600;
    color: #222;
    text-align: center;
    td {
      padding: 29px 40px;
    }
  }
  tbody {
    tr {
      td {
        border-top: 1px solid #ededed;
      }
      td:first-child {
        text-align: left;
      }
      .available {
        color: #4bb3ff;
      }
      .not_available {
        color: #ff4b4b;
      }
    }
  }
}

.shop_empty_list {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  img {
    margin-bottom: 10px;
    width: 30px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #808080;
  }
}
