.reviews_container {
  padding-block: 40px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  border-radius: 10px;
  border: solid 1px #ededed;
  background-color: #fff;
}

.reviews_form {
  margin-bottom: 30px;
  padding: 40px 30px 20px;
  border-bottom: 1px solid #ededed;
}

.reviews_profile {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 50px;

  .reviews_profile_info {
    margin-right: auto;
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 100%;
      margin-right: 6px;
      border-radius: 50%;
    }

    p {
      font-size: 14px;
      font-weight: bold;
      color: #222;
    }
  }

  .reviews_controls {
    display: flex;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 102px;
      height: 43px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .controls_delete {
      border: solid 1px #0046a6;
      background-color: #fff;
      color: #0046a6;
    }

    .controls_register {
      border: none;
      color: #fff;
      background-color: #0046a6;
    }
  }
}

.reviews_comment {
  width: 100%;

  textarea {
    resize: none;
    padding: 10px 20px;
    width: 100%;
    height: 116px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.86;
    color: #222;
  }

  textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.86;
    color: #808080;
  }
}

.reviews_content {
  margin-top: 40px;

  h3 {
    padding-inline: 30px;
    margin-bottom: 10px;
  }
}

.review_container {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-bottom: 1px solid #ededed;

  .review_info {
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;

    img {
      width: 50px;
      height: 100%;
      margin-right: 6px;
      border-radius: 50%;
    }

    .info_nickname {
      margin-right: auto;
      font-size: 14px;
      font-weight: bold;
      color: #222;
    }

    .info_date {
      font-size: 12px;
      font-weight: 500;
      color: #808080;
    }
  }

  .review_comment {
    font-size: 14px;
    font-weight: 500;
    color: #222;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    p {
      padding-right: 10px;
      word-break: break-all;
      white-space: pre-line;
    }
    button {
      outline: none;
      border: none;
      box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.load_btn {
  margin-block: 40px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 58px;
  border: none;
  border-radius: 100px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  &:disabled {
    background-color: #cccccc;
    cursor: default;
  }
}
