.credit_packs {
  background: url('../../img/credit_pack_bg.png') no-repeat;
  background-size: 136% 100%;
  padding: 100px 0 140px;
  background-position: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 10px;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  color: #222;
  text-transform: uppercase;
}

.subtitle {
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #808080;
}

.packs_container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.pack {
  box-sizing: border-box;
  cursor: pointer;
  padding: 30px;
  max-width: 270px;
  max-height: 318px;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.16);
  border: solid 1px #ededed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pack_name {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.25;
    color: #222;
  }

  .pack_price {
    margin-bottom: 4px;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.22;
    color: #ff6900;
  }

  .pack_detail {
    font-size: 10px;
    font-weight: 600;
    line-height: 1.3;
    color: #808080;
  }

  .pack_bonus {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    color: #222;
  }

  .pack_total_title {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    color: #808080;
  }

  .pack_total {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: #222;
  }

  .pack_hr {
    width: 210px;
    margin: 20px 0;
    border: 1px solid #ededed;
  }
}

.pack:hover {
  border: solid 1px #0046a6;
}

.total {
  margin: 0 auto 20px auto;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #0046a6;
  margin-top: 40px;
}

.payment_controls {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  .payment_credit {
    max-width: 217px;
    height: 55px;
    padding-inline: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 29px;
    border: solid 1px #222;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }
}
