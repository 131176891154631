.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal_content {
  max-width: 1320px;
  max-height: calc(100% - 100px);
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;

  .modal_inner_text {
    max-width: 1200px;
    max-height: 605px;
    margin: 40px;
    padding: 20px 10px 13px 20px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;
    font-size: 13px;
    font-weight: 500;
    color: #222;
    overflow-y: scroll;

    span {
      color: #222;
    }

    p {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
      line-height: 1.4;
      margin: 1rem;
    }
  }

  .modal_inner_text::-webkit-scrollbar {
    width: 4px;
    margin-block: 10px;
    writing-mode: horizontal-tb;
  }

  .modal_inner_text::-webkit-scrollbar-track,
  .modal_inner_text::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  .modal_inner_text::-webkit-scrollbar-track {
    margin-block: 20px;

    background-color: #ededed;
  }

  .modal_inner_text::-webkit-scrollbar-thumb {
    background-color: #222;
  }

  hr {
    margin: 0;
    border: 1px solid #ededed;
  }

  button {
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: center;
    border: none;
    width: 150px;
    height: 42px;
    min-height: 42px;
    padding: 12px 49px;
    border-radius: 21px;
    background-color: #0046a6;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
}

.modal_head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  min-height: 55px;
  background: #222;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}
