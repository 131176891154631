.title {
  font-size: 30px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.input_btn {
  position: absolute;
  bottom: 26px;
  right: 20px;
  border-radius: 14px;
  border: none;
  background-color: #0046a6;
  height: 21px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  text-transform: uppercase;
}

.bonus_credit {
  margin-top: 52px;
  display: flex;
  justify-content: center;
  .bonus_credit_box {
    width: 860px;
    // padding: 60px 40px;
    border-radius: 20px;
    border: solid 1px #ededed;
    background-color: #fff;

    .data_title {
      margin: 15px 40px;
      font-size: 14px;
      font-weight: 600;
      color: #222;
      margin-bottom: 10px;
    }

    .label_input {
      display: flex;
      width: 560px;
      margin-left: 40px;
      margin-top: 60px;
      flex-direction: column;
      position: relative;

      span {
        font-size: 14px;
        font-weight: bold;
        color: #222;
      }

      .input_group {
        border: solid 1px #ededed;
        border-radius: 8px;
        display: 'flex';
        align-items: center;
        padding: 10px;
        span {
          margin-left: 10px;
          margin-right: 10px;
        }

        input {
          padding: 0 10px;
        }
      }

      input {
        box-sizing: border-box;
        padding-left: 20px;
        width: 80px;
        height: 55px;
        border-radius: 10px;
        box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
        border: solid 1px #ededed;
        background-color: #fff;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        color: #222;
      }

      input:focus {
        outline: 1px solid #0046a6;
      }

      input::-webkit-calendar-picker-indicator {
        opacity: 100;
        margin-right: 20px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .message {
      font-size: 12px;
      font-weight: 600;
      padding-left: 40px;
      padding-top: 4px;
      &.certified {
        color: #4bb3ff;
      }
      &.error {
        color: #ff4b4b;
      }
    }

    hr {
      border-top: 1px solid #ededed;
      border-bottom: none;
      margin-top: 21px;
      margin-bottom: 20px;
    }

    .entitled_bonus_credits {
      width: 780px;
      height: 82px;
      border-radius: 10px;
      box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
      background-color: #f6f7f9;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      p {
        font-size: 18px;
        font-weight: 600;
        color: #222;
        span:last-child {
          color: #ff6900;
        }
      }
    }
    .btn_confirm {
      display: flex;
      justify-content: center;
      margin: 60px 0 80px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 444px;
        height: 58px;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        border: none;
        color: #fff;
        background-color: #0046a6;
        border-radius: 30px;
        box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
      }
    }
    .bonus_credit_table {
      margin: 0 40px 60px;
      .title_table {
        font-size: 16px;
        font-weight: bold;
        color: #222;
        margin-bottom: 10px;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        td {
          padding: 18px 0;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          color: #222;
        }
        tr {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
        thead tr td {
          border-top: 1px solid #0046a6;
          background-color: #f5f9ff;
        }
        tbody tr td {
          border-top: 1px solid #ededed;
        }
        tbody tr:last-child td {
          border-bottom: 1px solid #ededed;
        }
      }
    }
  }
}
