.card_container {
  min-width: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
  border: solid 1px #ededed;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .card_product {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 319px;

    img {
      cursor: pointer;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card_cart_btn {
    margin-right: 18px;
    align-self: flex-end;
    position: relative;
    top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 10px 0 rgba(165, 189, 213, 0.3);
    margin-bottom: -25px;

    img {
      width: 24px;
      height: 20px;
    }
  }

  .card_info {
    padding-inline: 20px;
  }

  h3 {
    margin-top: 3px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    text-transform: capitalize;
  }

  button {
    width: 100%;
    height: 46px;
    margin: 0 auto 30px;
    border-radius: 30px;
    box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
    border: solid 1px #0046a6;
    background: transparent;
    font-size: 14px;
    font-weight: bold;
    color: #0046a6;
    text-transform: uppercase;
  }
}
