.product_section {
  max-width: 1200px;
  margin: 60px auto;
}

.product_container {
  display: flex;
  flex-flow: row wrap;
  gap: 30px;

  .product_img {
    width: 666px;
    object-fit: fill;
    border-radius: 10px;
  }

  .product_info {
    max-width: 504px;
    max-height: 442px;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fff;

    hr {
      border: 0.5px solid #ededed;
      margin: 0;
    }
  }

  .product_content {
    margin: 30px;

    h2 {
      font-size: 18px;
      font-weight: bold;
      color: #222;
      margin-bottom: 4px;
      text-transform: uppercase;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #808080;
      margin-bottom: 30px;
      text-transform: capitalize;
    }

    label {
      display: flex;
      flex-direction: column;
      position: relative; 

      p {
        font-size: 14px;
        font-weight: bold;
        color: #222;
        margin-bottom: 10px;
      }

      select,
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        min-width: 444px;

        border-radius: 10px;
        box-shadow: 0 8px 30px 0 rgba(165, 189, 213, 0.11);
        border: solid 1px #ededed;
        background-color: #fff;
      }

      button {
        height: 56px;
        font-size: 14px;
        font-weight: 500;
        // color: #bababa;

        img {
          width: 8px;
          height: 6px;
          transform: rotate(180deg);
        }
      }

      select {
        position: absolute;
        top: 85px;
        z-index: 150;
        margin-top: 5px;
        appearance: none;
        outline: none;
        overflow: hidden;
        max-height: 185px;
        overflow-y: scroll;

        option {
          cursor: pointer;
        }

        option:not(:first-child) {
          margin-top: 20px;
        }
      }

      select::-webkit-scrollbar {
        width: 4px;
        writing-mode: horizontal-tb;
      }

      select::-webkit-scrollbar-track,
      select::-webkit-scrollbar-thumb {
        border-radius: 8px;
      }

      select::-webkit-scrollbar-track {
        margin-block: 5px;

        background-color: #ededed;
      }

      select::-webkit-scrollbar-thumb {
        background-color: #222;
      }

      option {
        margin-bottom: 20px;
        margin-right: 20px;
      }

      option:hover {
        color: #0046a6;
        background-color: #fff;
      }

      option:active {
        background: #fff linear-gradient(0deg, #fff 0%, #fff 100%);
      }
    }
  }

  .product_price {
    margin-block: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #0046a6;
  }

  .product_btns {
    margin: 40px 30px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      width: 217px;
      height: 58px;
      border-radius: 30px;
      box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .btn_add_cart {
      background: transparent;
      border: 1px solid #222;
      color: #222;
    }

    .btn_buy {
      background: #0046a6;
      border: none;
      color: #fff;
    }
  }
}

.product_nav {
  display: flex;
  align-items: center;
  height: 59px;

  a {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
  }

  .nav_info {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .nav_reviews {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
