.form_section {
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: 85vh;
  background-color: #f6f7f9;
}

.form_section::-webkit-scrollbar {
  display: none;
}

.form {
  max-width: 860px;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input:focus {
    outline: 1px solid #0046a6;
  }
}

.title {
  margin-top: 80px;
  margin-bottom: 30px;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  color: #333;
  text-transform: uppercase;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 53.49%;
  margin-bottom: 80px;
}

.submit_btn {
  margin-bottom: 40px;
  border: none;
  width: 100%;
  max-height: 58px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0 8px 30px 0 rgba(0, 70, 166, 0.11);
  background-color: #0046a6;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #fff;
  text-transform: uppercase;
}

.submit_btn:disabled {
  background-color: #cccccc;
}

.warnings_holder {
  position: relative;
  width: 100%;
  text-align: center;
}

.validation_warning {
  width: 100%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  color: #ff4b4b;
}
